import React from 'react';
import EmptyLine from 'components/EmptyLine';
import withOrderService from 'hocs/withOrderService';
import withUser from 'hocs/withUser';
import * as ComponentUtils from 'utils/component';
import CartItemPanel from './CartItemPanel';
import SectionWrapper from './SectionWrapper';

const Carts = ({ user, cart, orderSessionData }) => {
    const { userId } = user;
    const { shareCart } = orderSessionData;

    const renderCart = (key, iconName, iconLabel, cartItems) => {
        const renderCartItem = (cartItem, isLastItem) => {
            return (
                <React.Fragment key={cartItem.cartItemId}>
                    <CartItemPanel cartItem={cartItem} />
                    {!isLastItem && <EmptyLine className="mt-3" />}
                </React.Fragment>
            );
        };

        return (
            <SectionWrapper
                key={key}
                iconName={iconName}
                iconLabel={iconLabel}
            >
                {cartItems.map((cartItem, index) => renderCartItem(cartItem, index === cartItems.length - 1))}
            </SectionWrapper>
        );
    };

    return (
        <>
            {shareCart
                ? user.items
                    .reduce((arr, item) => {
                        const userCartItems = cart.items.filter(x => x.userId === item.userId);
                        if (userCartItems.length > 0) {
                            arr.push({
                                userId: item.userId,
                                items: userCartItems,
                                seq: item.seq
                            });
                        }

                        return arr;
                    }, [])
                    .sort((a, b) => a.userId === userId ? -1 : b.userId === userId ? 1 : 0)
                    .map(x => renderCart(x.userId, 'icon-icon-guest2', `Guest ${x.seq} ${x.userId === userId ? '(You)' : ''}`, x.items))
                : renderCart(userId, 'fas fa-shopping-basket', 'Cart', cart.items)
            }
        </>
    );
};

const hocs = [
    withUser,
    withOrderService
];

export default ComponentUtils.compose(hocs)(Carts);