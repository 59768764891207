const Icon = ({ className, style, name, label, showIconFirst = true, ...rest }) => (
    <div
        className={className}
        style={style}
        {...rest}
    >
        {showIconFirst
            ? <>
                <span className={`me-2 ${name}`} />
                <span>{label}</span>
            </>
            : <>
                <span className="me-2">{label}</span>
                <span className={name} />
            </>
        }
    </div>
);

export default Icon;