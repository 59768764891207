import React from 'react';
import * as DocumentIdConstants from 'data/documentIdConstant';

const ScrollspyNav = React.forwardRef((props, ref) => {
    const { menuCategories, openMenuCategoryListDialog } = props;

    return (
        <div className="apps-page-menu-scrollspy" ref={ref}>
            <div className="d-flex align-items-center">
                <ul
                    id={DocumentIdConstants.PageMenuNavTab}
                    className="d-flex mb-0 ps-0"
                >
                    {menuCategories.map(x =>
                        <li key={x.menuCategoryGuid} className="px-3 py-2">
                            <a href={`#${x.menuCategoryGuid}`}>{x.name}</a>
                        </li>
                    )}
                </ul>

                <div
                    className="fas fa-chevron-down d-flex align-items-center justify-content-center fs-2 ms-auto me-2"
                    style={{
                        width: '40px',
                        height: '40px',
                        padding: '10px'
                    }}
                    onClick={openMenuCategoryListDialog}
                />
            </div>
        </div>
    );
});

export default ScrollspyNav;
