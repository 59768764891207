import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import * as ApplicationApi from 'apis/applicationApi';
import BlockUI from 'components/BlockUI';
import * as ServiceTypeConstants from 'data/serviceTypeConstant';
import * as HomePageErrorCodes from 'pages/Home/data/errorCode';
import * as Session from 'utils/session';
import { pageNames, pagePaths, pageRoutes } from 'utils/routes';
import * as RoutesUtils from 'utils/routesUtils';
import * as ThemeUtils from 'utils/themeUtils';
import App from 'App';

ThemeUtils.updateTheme();

const rootElement = ReactDOM.createRoot(document.getElementById('root'));
const blockUiElement = ReactDOM.createRoot(document.getElementById('blockui'));
const showLoading = () => blockUiElement.render(<BlockUI blocking />);
const hideLoading = () => blockUiElement.unmount();

const urlParams = new URLSearchParams(window.location.search);
const urlAccountBookId = urlParams.get('accountBookId');
const urlQRCode = urlParams.get('qrCode');

const onQRCodeScanned = async () => {
    showLoading();

    Session.removeSessionData(); // Remove data from session storage
    Session.setAccountBookId(urlAccountBookId);

    try {
        const result = await ApplicationApi.validateQRCode(urlQRCode);
        hideLoading();

        if (result.isQRCodeInvalid) {
            RoutesUtils.redirectToPageByPath(pagePaths.QRCodeInvalid);
        } else {
            Session.setQRCode(result.qrCodeId);
            Session.setTableNo(result.tableNo);
            Session.setServiceType(result.serviceType);
            Session.setIsQRCodeStatic(result.isQRCodeStatic);
            Session.setIsPreview(result.isPreview);

            const options = result.isQRCodeExpired
                ? { errorCode: HomePageErrorCodes.QRCodeExpired }
                : null;

            RoutesUtils.redirectToPageByName(pageNames.Home, options);
        }
    } catch {
        hideLoading();
        RoutesUtils.redirectToPageByPath(pagePaths.QRCodeInvalid);
    }
};

const onAppInitialized = async () => {
    const parseIntValue = (value) => {
        const intValue = parseInt(value);
        return isNaN(intValue) ? null : intValue;
    };

    const buildBrowserRouter = () => createBrowserRouter([
        {
            path: "/",
            element: <App />,
            children: pageRoutes.map(route => ({
                path: route.path,
                element: <route.component />
            }))
        }
    ]);

    const accountBookId = parseIntValue(Session.getAccountBookId());
    const tableSessionId = parseIntValue(Session.getTableSessionId());
    const serviceType = Session.getServiceType();
    const isPreview = Session.getIsPreview();

    let qrCode = Session.getQRCode();
    let tableNo = Session.getTableNo();

    // Check QR Code is valid
    const isDineIn = serviceType === ServiceTypeConstants.DineIn && tableNo;
    const isTakeAway = serviceType === ServiceTypeConstants.TakeAway;

    const isValidQRCode = accountBookId && qrCode && (isDineIn || isTakeAway || isPreview);
    if (!isValidQRCode) {
        RoutesUtils.redirectToPageByPath(pagePaths.QRCodeInvalid);
        return;
    }

    // Update qrCode & tableNo if table has changed since last session
    if (tableSessionId && tableNo) {
        showLoading();

        try {
            const result = await ApplicationApi.hasChangeTable(qrCode, tableSessionId);
            hideLoading();

            if (result.hasChangeTable) {
                Session.setQRCode(result.qrCodeId);
                Session.setTableNo(result.tableNo);
                qrCode = result.qrCodeId;
                tableNo = result.tableNo;
            }
        } catch {
            hideLoading();
            RoutesUtils.redirectToPageByPath(pagePaths.QRCodeInvalid);
            return;
        }
    }

    // Ensure user is on the Home Page or a valid redirect path
    const pathName = window.location.pathname;
    if (!pathName.startsWith(pagePaths.Home) && !pathName.startsWith('/redirect')) {
        RoutesUtils.redirectToPageByName(pageNames.Home);
    }

    rootElement.render(
        <RouterProvider router={buildBrowserRouter()} />
    );
};

if (urlAccountBookId && urlQRCode) {
    onQRCodeScanned();
} else {
    onAppInitialized();
}