import React, { useState, useEffect, useRef } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Image from 'components/Image';
import * as EventUtils from 'utils/eventUtils';

const ImageCarouselInner = ({
    data,
    slide = true,
    fade = false,
    controls = data.length > 1,
    indicators = data.length > 1,
    interval = 3000,
    pause = 'hover',
    wrap = true,
    touch = true,
    imageStyle,
    forwardedRef,
    ...rest }) => {

    const [activeIndex, setActiveIndex] = useState(0);

    const ref = useRef();

    useEffect(() => {
        if (ref.current && forwardedRef) {
            forwardedRef.current = ref.current.element;
        }
    }, [forwardedRef]);

    const onItemSelected = (selectedIndex, e) => {
        EventUtils.stopPropagation(e);
        setActiveIndex(selectedIndex);
    };

    return (
        <Carousel
            slide={slide}
            fade={fade}
            controls={controls}
            indicators={indicators}
            interval={interval}
            pause={pause}
            wrap={wrap}
            touch={touch}
            activeIndex={activeIndex}
            onSelect={onItemSelected}
            ref={ref}
            {...rest}
        >
            {data.map((x, index) => (
                <Carousel.Item key={index}>
                    <Image
                        className="w-100 h-100"
                        style={imageStyle}
                        imageSrc={x}
                    />
                </Carousel.Item>
            ))}
        </Carousel>
    );
};

const ImageCarousel = React.forwardRef((props, ref) => <ImageCarouselInner {...props} forwardedRef={ref} />);

export default ImageCarousel;