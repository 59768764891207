export function submitPaymentGatewayForm(data, blockUI, delayFormSubmission) {
    blockUI('Please wait, you will be redirected to Payment Gateway shortly.');

    const form = createPaymentGatewayForm(data);
    document.body.appendChild(form);

    if (delayFormSubmission) {
        setTimeout(() => submitForm(form), 100);
    } else {
        submitForm(form);
    }
}

function createPaymentGatewayForm(data) {
    const { requestDataInJson, requestUrl, requestName } = data;
    const requestData = JSON.parse(requestDataInJson);

    const form = document.createElement('form');
    form.setAttribute('method', 'post');
    form.setAttribute('action', requestUrl);

    if (requestName) {
        form.setAttribute("name", requestName);
    }

    // Append form inputs
    for (const [key, value] of Object.entries(requestData)) {
        const input = document.createElement('input');
        input.setAttribute('type', 'hidden');
        input.setAttribute('name', key);
        input.setAttribute('value', value || ' ');
        form.appendChild(input);
    }

    // Append submit button
    const submitInput = document.createElement('input');
    submitInput.setAttribute('type', 'submit');
    form.appendChild(submitInput);

    return form;
}

function submitForm(form) {
    form.querySelector('input[type="submit"]').click();
}