import React, { useRef, useEffect, useState, useImperativeHandle } from 'react';
import Dialog from 'components/Dialog';
import TabPanel from 'components/TabPanel';
import TabContent from 'components/TabContent';
import withBlockUI from 'hocs/withBlockUI';
import withOrderService from 'hocs/withOrderService';
import withUserDeviceSettings from 'hocs/withUserDeviceSettings';
import * as ComponentUtils from 'utils/component';
import * as Toast from 'utils/toast';
import { InCartTabFooter } from './InCartTab/Footer';
import InCartTab from './InCartTab/InCartTab';
import { OrderedTabFooter } from './OrderedTab/Footer';
import OrderedTab from './OrderedTab/OrderedTab';

const OrderDialogInner = ({
    orderService,
    isPageVisible,
    isNetworkOnline,
    cart,
    unblockUI,
    tabKey: initialTabKey,
    closeDialog,
    confirmOrder,
    makePayment,
    forwardedRef }) => {

    const [tabKey, setTabKey] = useState(initialTabKey);
    const [orders, setOrders] = useState([]);
    const [lastSyncOrderTimeStamp, setLastSyncOrderTimeStamp] = useState(null);

    const tabRef = useRef();
    const syncOrdersIconRef = useRef();
    const syncOrdersTimer = useRef(null);

    useImperativeHandle(forwardedRef, () => ({
        show: (tabKey) => tabRef.current.show(tabKey),
        syncOrders: (isTriggeredByTimer) => syncOrders(isTriggeredByTimer)
    }));

    useEffect(() => {
        syncOrders(false);
        window.addEventListener('pageshow', onPageShow);

        return () => {
            deleteSyncOrdersTimer();
            window.removeEventListener('pageshow', onPageShow);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (syncOrdersTimer.current !== null) {
            deleteSyncOrdersTimer();
            createSyncOrdersTimer();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPageVisible, isNetworkOnline, tabKey]);

    const onPageShow = () => {
        // Note : This is necessary to handle cases where the user clicks the mobile back button after being redirected to the Payment Gateway.
        const blockUIEl = document.querySelector('.apps-component-block-ui-overlay');

        if (blockUIEl && blockUIEl.classList.contains('blocking')) {
            unblockUI();
        }
    };

    const onTabChanged = async (newTabKey) => {
        const isChangingToOrderedTab = isOrderedTab(newTabKey);
        const isCurrentlyNotInOrderedTab = !isOrderedTab(tabKey);

        if (isChangingToOrderedTab && isCurrentlyNotInOrderedTab) {
            await syncOrders(false, true);
        }

        setTabKey(newTabKey);
    };

    const syncOrders = (isTriggeredByTimer, showSpinAnimation = false) => {
        const spinClassName = 'fa-spin';

        const syncOrdersIconEl = syncOrdersIconRef.current;
        const syncOrdersIconElClassList = syncOrdersIconEl.classList;

        if (!isTriggeredByTimer) {
            deleteSyncOrdersTimer();
        }

        if (showSpinAnimation) {
            syncOrdersIconElClassList.add(spinClassName);
        }

        return new Promise(resolve => {
            orderService.getOrders()
                .then(orders => {
                    setOrders(orders);
                    setLastSyncOrderTimeStamp(new Date());
                })
                .catch(errorMessage => {
                    Toast.showErrorMessage(errorMessage);
                })
                .finally(() => {
                    if (!isTriggeredByTimer) {
                        createSyncOrdersTimer();
                    }

                    if (showSpinAnimation) {
                        syncOrdersIconElClassList.remove(spinClassName);
                    }

                    resolve();
                });
        });
    };

    const createSyncOrdersTimer = () => {
        // Create a timer that sync orders automatically every 5 seconds
        if (syncOrdersTimer.current !== null) return;

        syncOrdersTimer.current = setInterval(() => {
            if (isPageVisible && isNetworkOnline && isOrderedTab(tabKey)) {
                syncOrders(true);
            }
        }, 5000);
    };

    const deleteSyncOrdersTimer = () => {
        // Delete timer
        if (syncOrdersTimer.current === null) return;

        clearInterval(syncOrdersTimer.current);
        syncOrdersTimer.current = null;
    };

    const isInCartTab = (tabKey) => {
        return tabKey === 'order0';
    };

    const isOrderedTab = (tabKey) => {
        return tabKey === 'order1';
    };

    return (
        <Dialog
            title="Your Order Cart"
            isOpen
            fullScreen
            closeDialog={closeDialog}
            footer={(
                <>
                    {isInCartTab(tabKey) && cart.hasAnyItems() && <InCartTabFooter confirmOrder={confirmOrder} />}
                    {isOrderedTab(tabKey) && orders.length > 0 && <OrderedTabFooter orders={orders} />}
                </>
            )}
        >
            <TabContent
                tabPrefix="order"
                defaultTabKey={isOrderedTab() ? 'order1' : undefined}
                top={0}
                height='50px'
                sticky
                boxShadow
                onTabChanged={onTabChanged}
                ref={tabRef}
            >
                <TabPanel title="In Cart">
                    <InCartTab closeDialog={closeDialog} />
                </TabPanel>

                <TabPanel title="Ordered">
                    <OrderedTab
                        orders={orders}
                        lastSyncOrderTimeStamp={lastSyncOrderTimeStamp}
                        closeDialog={closeDialog}
                        makePayment={makePayment}
                        syncOrders={syncOrders}
                        ref={syncOrdersIconRef}
                    />
                </TabPanel>
            </TabContent>
        </Dialog>
    );
};

const hocs = [
    withBlockUI,
    withOrderService,
    withUserDeviceSettings
];

const OrderDialogWithHocs = ComponentUtils.compose(hocs)(OrderDialogInner);

const OrderDialog = React.forwardRef((props, ref) => <OrderDialogWithHocs {...props} forwardedRef={ref} />);

export default React.memo(OrderDialog);