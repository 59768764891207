import BottomSheetDialog from 'components/BottomSheetDialog';
import Button from 'components/Button';
import EmptyLine from 'components/EmptyLine';
import HintText from 'components/HintText';
import Icon from 'components/Icon';
import MenuItemName from 'components.special/MenuItemName';
import MenuItemImage from 'components.special/MenuItemImage';
import MenuItemPrice from 'components.special/MenuItemPrice';
import MenuItemQuantityIcon from 'components.special/MenuItemQuantityIcon';
import MenuItemTakeAway from 'components.special/MenuItemTakeAway';
import * as ServiceTypeConstants from 'data/serviceTypeConstant';
import withOrderService from 'hocs/withOrderService';
import * as ComponentUtils from 'utils/component';

const ItemsAlreadyInCartDialog = ({ cart, menuItem, addItem, updateItem, closeDialog }) => {
    const cartItems = cart.items.filter(item => item.menuItemGuid === menuItem.menuItemGuid);

    const renderCartItem = (cartItem, isLastCartItem) => (
        <div
            key={cartItem.cartItemId}
            className="d-flex flex-column px-3"
        >
            <div className="d-flex py-3">
                <div className="position-relative">
                    <MenuItemImage
                        className="border"
                        style={{ borderRadius: '6px' }}
                        name={cartItem.name}
                        imageSrc={cartItem.image}
                        width="70px"
                        height="70px"
                    />

                    <MenuItemQuantityIcon
                        className="position-absolute"
                        style={{ top: '-10px', left: '-5px' }}
                        border
                        shadow
                    >
                        {cartItem.quantity}
                    </MenuItemQuantityIcon>
                </div>

                <div
                    className="flex-grow-1 d-flex flex-column mx-3"
                    style={{ overflowWrap: 'anywhere' }}
                >
                    <span className="fs-5" style={{ fontWeight: 500 }}>
                        <MenuItemName displayCode={cartItem.displayCode} name={cartItem.name} />
                    </span>

                    {renderCustomizationDetails(cartItem)}
                </div>

                <div
                    className="d-flex flex-column align-items-end justify-content-between ms-auto"
                    style={{ textWrap: 'nowrap' }}
                >
                    <Button
                        className="fs-5 mb-2"
                        primary
                        outline
                        onClick={() => updateItem(cartItem.cartItemId)}
                    >
                        <Icon name="fas fa-pencil-alt" label="Edit" />
                    </Button>

                    <MenuItemPrice as="div" value={cartItem.subTotal} prefixSmallFont />
                </div>
            </div>

            {!isLastCartItem && <EmptyLine />}
        </div>
    );

    const renderCustomizationDetails = (cartItem) => {
        const renderModifierItemDetails = (items) => (
            items.length > 0 && (
                items.map(item => item.description).join(' | ')
            )
        );

        const renderSetMealItemDetails = () => (
            cartItem.setMealItems.map((item, index) => (
                <HintText key={index} className="mt-2">
                    <div>{item.name} x{item.quantity}</div>
                    {item.modifierItems.length > 0 && (
                        <span>- {renderModifierItemDetails(item.modifierItems)}</span>
                    )}
                </HintText>
            ))
        );

        return (
            <>
                {cartItem.serviceType === ServiceTypeConstants.TakeAway &&
                    <HintText className="mt-2">
                        <MenuItemTakeAway name={cartItem.takeAwayItem?.name} />
                    </HintText>
                }

                {cartItem.menuSetMealGuid && renderSetMealItemDetails()}

                {!cartItem.menuSetMealGuid && cartItem.modifierItems.length > 0 &&
                    <HintText className="mt-2">
                        {renderModifierItemDetails(cartItem.modifierItems)}
                    </HintText>
                }

                {cartItem.remark &&
                    <HintText className="mt-2">"{cartItem.remark}"</HintText>
                }
            </>
        );
    };

    return (
        <BottomSheetDialog
            isOpen
            title="Items Already In Cart"
            closeDialog={closeDialog}
            footer={
                <div
                    className="d-flex align-items-center justify-content-center p-3"
                    style={{ borderTop: '1px solid var(--grey-300)', boxShadow: 'var(--box-shadow)' }}
                >
                    <div
                        className="flex-grow-1 d-flex align-items-center justify-content-center text-white bg-primary py-2"
                        style={{ borderRadius: '6px', cursor: 'pointer' }}
                        onClick={addItem}
                    >
                        <Icon name="fas fa-plus" label="Add Another" />
                    </div>
                </div>
            }
        >
            {cartItems.map((x, index) => renderCartItem(x, index === cartItems.length - 1))}
        </BottomSheetDialog>
    );
};

const hocs = [
    withOrderService
];

export default ComponentUtils.compose(hocs)(ItemsAlreadyInCartDialog);