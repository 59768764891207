import React from 'react';
import HintText from 'components/HintText';
import Image from 'components/Image';
import { getServiceTypeText } from 'data/serviceTypeText';
import withOrderService from 'hocs/withOrderService';
import withSettings from 'hocs/withSettings';
import * as ComponentUtils from 'utils/component';

const RestaurantInfoInner = ({ settings, orderSessionData, forwardedRef }) => {
    const { viewName, description, viewLogo } = settings;
    const { orderServiceType, tableNo } = orderSessionData;

    return (
        <div
            className="d-flex align-items-center position-relative bg-white px-2 py-3"
            style={{
                borderTopLeftRadius: '1.5rem',
                borderTopRightRadius: '1.5rem',
                marginTop: '-2vh',
                zIndex: 1
            }}
            ref={forwardedRef}
        >
            <div className="mx-2">
                <Image
                    className="border rounded-circle"
                    style={{ objectFit: 'contain' }}
                    width="80px"
                    height="80px"
                    imageSrc={viewLogo ? viewLogo : '/img/home-default-restaurant-logo.png'}
                />
            </div>

            <div className="flex-grow-1 d-flex flex-column ms-2">
                <div className="fw-bold fs-3">
                    {viewName}
                </div>

                {description &&
                    <HintText as="div" className="mt-2">
                        {description}
                    </HintText>
                }

                <div className="d-flex align-items-center mt-2">
                    <HintText className="me-4">
                        <span className="fas fa-utensils me-2"></span>
                        <span>{getServiceTypeText(orderServiceType)}</span>
                    </HintText>

                    {tableNo &&
                        <HintText>
                            <span className="fas fa-chair me-2"></span>
                            <span>{tableNo}</span>
                        </HintText>
                    }
                </div>
            </div>
        </div>
    );
};

const hocs = [
    withOrderService,
    withSettings
];

const RestaurantInfoWithHocs = ComponentUtils.compose(hocs)(RestaurantInfoInner);

const RestaurantInfo = React.forwardRef((props, ref) => <RestaurantInfoWithHocs {...props} forwardedRef={ref} />);

export default RestaurantInfo;