import withSettings from 'hocs/withSettings';
import * as ComponentUtils from 'utils/component';
import * as DayUtils from 'utils/dayUtils';

const RestaurantOperatingHours = ({ settings, className }) => {
    const { restaurantOperatingDays } = settings;

    return (
        <div className={`d-flex flex-column ${className ? className : ''}`}>
            {restaurantOperatingDays
                .sort((a, b) => a.day === 0 ? 1 : a.day - b.day) // Note : Sort RestaurantOperatingDay from Monday to Sunday
                .map((x, index) => {
                    const { day, isOpen, formattedOperatingHour } = x;
                    const isFirstItem = index === 0;
                    const isToday = new Date().getDay() === day;

                    return (
                        <div
                            key={`restaurant-operating-day-${day}`}
                            className={`d-flex align-items-center fs-5 ${isToday ? 'fw-bold' : ''} ${isFirstItem ? '' : 'mt-1'}`}
                        >
                            <span>
                                {DayUtils.dayToString(day)}
                            </span>

                            <span className="flex-grow-1 text-end ms-3">
                                {isOpen ? formattedOperatingHour : 'Closed'}
                            </span>
                        </div>
                    );
                })}
        </div>
    );
};

const hocs = [
    withSettings
];

export default ComponentUtils.compose(hocs)(RestaurantOperatingHours);