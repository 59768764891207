import * as EventUtils from 'utils/eventUtils';

const MenuItemQuantityPanel = ({ className, height = '35px', width = '35px', value, handleAdd, handleMinus }) => {
    const childClasses = 'col-4 align-content-center text-center h-100';
    const childCss = { width };

    return (
        <div
            className={`d-flex align-items-center ${className ? className : ''}`}
            style={{
                height,
                borderRadius: '10px',
                boxShadow: 'var(--box-shadow)'
            }}
        >
            {/* Minus Button */}
            <span
                className={`fas fa-minus text-white bg-primary ${childClasses}`}
                style={{
                    borderTopLeftRadius: '10px',
                    borderBottomLeftRadius: '10px',
                    ...childCss
                }}
                onClick={handleMinus}
            />

            {/* Quantity */}
            <span
                className={`fw-bold ${childClasses}`}
                style={{ border: '1px solid var(--grey-400)', ...childCss }}
                onClick={e => EventUtils.stopPropagation(e)}
            >
                {value}
            </span>

            {/* Add Button */}
            <span
                className={`fas fa-plus text-white bg-primary ${childClasses}`}
                style={{
                    borderTopRightRadius: '10px',
                    borderBottomRightRadius: '10px',
                    ...childCss
                }}
                onClick={handleAdd}
            />
        </div>
    );
}

export default MenuItemQuantityPanel;