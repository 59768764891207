import Button from 'components/Button';
import MenuItemPrice from 'components.special/MenuItemPrice';

const Footer = ({ qty, maxQty = undefined, price, form, buttonText, handleMinus, handleAdd }) => {
    const canMinus = qty > 1;
    const canAdd = maxQty === undefined || maxQty > qty;

    const qtyPanelChildClasses = 'col-4 d-flex justify-content-center';

    return (
        <div
            className="d-flex align-items-center px-2 py-3"
            style={{ borderTop: '1px solid var(--grey-300)', boxShadow: 'var(--box-shadow)' }}
        >
            {/* Quantity Panel */}
            <div className="col-4">
                <div className="d-flex align-items-center">
                    <div
                        className={`${qtyPanelChildClasses} icon-icon-remove`}
                        style={{ fontSize: '28px', color: canMinus ? 'var(--primary)' : 'var(--grey-300)' }}
                        onClick={e => canMinus && handleMinus(e)}
                    />

                    <div className={qtyPanelChildClasses}>
                        <span className="mx-2 fw-bold">{qty}</span>
                    </div>

                    <div
                        className={`${qtyPanelChildClasses} icon-icon-plus`}
                        style={{ fontSize: '28px', color: canAdd ? 'var(--primary)' : 'var(--grey-300)' }}
                        onClick={e => canAdd && handleAdd(e)}
                    />
                </div>
            </div>

            {/* Submit Button */}
            <div className="col-8 ps-2">
                <Button form={form} className="w-100" primary submit>
                    <div className="d-flex align-items-center">
                        <span className="icon-icon-add-to-cart fs-1 me-2" style={{ lineHeight: 0.8 }} />
                        <span className="me-3">{buttonText}</span>
                        <MenuItemPrice
                            as="span"
                            className="ms-auto"
                            style={{ fontWeight: 600 }}
                            value={price}
                            isCurrencyFormat
                        />
                    </div>
                </Button>
            </div>
        </div>
    );
}

export default Footer;