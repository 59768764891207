import React, { useState, useRef, useImperativeHandle } from 'react';
import withSettings from 'hocs/withSettings';
import * as ComponentUtils from 'utils/component';
import SearchBar from './SearchBar';

const AppBarInner = ({ settings, menuCategories, onSearchValueChanged, openSidenav, forwardedRef }) => {
    const { viewName } = settings;

    const [isTransparent, setIsTransparent] = useState(true);
    const [showSearchBar, setShowSearchBar] = useState(false);

    const ref = useRef();

    useImperativeHandle(forwardedRef, () => ({
        getScrollHeight: () => ref.current.scrollHeight,
        setAppBarTransparency: (value) => setIsTransparent(value)
    }));

    const openSearchBar = () => {
        setShowSearchBar(true);
    }

    const closeSearchBar = () => {
        setShowSearchBar(false);
        onSearchValueChanged(null);
    }

    const renderIcon = (className, onClick) => {
        return (
            <div
                className={`d-flex align-items-center justify-content-center rounded-circle fs-2 bg-white ${className}`}
                style={{
                    width: '40px',
                    height: '40px',
                    padding: '10px'
                }}
                onClick={onClick}
            />
        );
    }

    return (
        <div
            className={`d-flex align-items-center position-fixed top-0 w-100 ${isTransparent ? '' : 'bg-white'}`}
            style={{
                maxWidth: 'var(--max-width)',
                height: '50px',
                zIndex: 2,
                transition: 'all 0.15s ease-in-out',
                boxShadow: isTransparent ? undefined : 'var(--box-shadow)'
            }}
            ref={ref}
        >
            {showSearchBar
                ? <>
                    <SearchBar onSearchValueChanged={onSearchValueChanged} />
                    {renderIcon('fas fa-times ms-auto me-2', closeSearchBar)}
                </>
                : <>
                    {renderIcon('fas fa-bars ms-2', openSidenav)}

                    {!isTransparent &&
                        <div
                            className="flex-grow-1 fw-bold"
                            style={{
                                marginLeft: '1rem',
                                marginRight: '1rem',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis'
                            }}
                        >
                            {viewName}
                        </div>
                    }

                    {menuCategories.length > 0 &&
                        renderIcon('fas fa-search ms-auto me-2', openSearchBar)
                    }
                </>
            }
        </div>
    );
};

const hocs = [
    withSettings
];

const AppBarWithHocs = ComponentUtils.compose(hocs)(AppBarInner);

const AppBar = React.forwardRef((props, ref) => <AppBarWithHocs {...props} forwardedRef={ref} />);

export default AppBar;