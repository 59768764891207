import HintText from 'components/HintText';
import Image from 'components/Image';

const MenuItemNotFound = ({ children }) =>
    <div className="d-flex flex-column align-items-center justify-content-center bg-white">
        <Image imageSrc="/img/empty-menu-item-logo.png" />

        <span className="fw-bold mt-3">Item Not Found</span>

        <HintText className="my-3">
            {children}
        </HintText>

        <div className="mt-4" />
    </div>

export default MenuItemNotFound;