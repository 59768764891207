import * as FormValidationConstants from '../../data/formValidationConstant';

const ValidatorElement = ({ message }) => {
    return (
        <div
            className={`${FormValidationConstants.InvalidFeedbackClass} p-1 mt-3`}
            style={{
                background: '#ffebeb',
                width: 'fit-content',
                borderRadius: '5px',
                display: 'none'
            }}
        >
            <div className="icon-icon-warning fs-2" />
            <div className="fs-6" style={{ color: '#e2430a' }}>{message}</div>
        </div>
    )
}

export default ValidatorElement;