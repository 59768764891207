import { Component } from 'react';
import MenuContext from 'contexts/MenuContext';
import { getDisplayName } from 'utils/component';

export default function withMenu(WrappedComponent) {
    class WithMenu extends Component {
        render() {
            const { menu } = this.context;

            return (
                <WrappedComponent
                    menu={menu}
                    {...this.props}
                />
            );
        }
    }

    WithMenu.displayName = `WithMenu(${getDisplayName(WrappedComponent)})`;
    WithMenu.contextType = MenuContext;

    return WithMenu;
}