import React from 'react';
import BottomSheetDialog from 'components/BottomSheetDialog';
import Image from 'components/Image';
import { paymentMethodText } from '../data/paymentMethodText';

const PaymentMethodDialog = ({ closeDialog, handleSubmit }) => (
    <BottomSheetDialog
        title="Select Payment Method"
        isOpen
        closeDialog={closeDialog}
    >
        <div className="d-flex flex-wrap p-3">
            {paymentMethodText.map(x => (
                <div
                    key={x.value}
                    className="d-flex flex-column align-items-center border rounded-3 px-2 py-3"
                    style={{
                        flex: '0 0 calc(50% - 1rem)',
                        margin: '0.5rem',
                        cursor: 'pointer'
                    }}
                    onClick={() => handleSubmit(x.value)}
                >
                    <Image style={{ width: '60%' }} imageSrc={x.imageSrc} />

                    <span className="mt-2 text-center">{x.text}</span>
                </div>
            ))}
        </div>
    </BottomSheetDialog>
);

export default React.memo(PaymentMethodDialog);