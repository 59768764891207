import CartCalculator from './CartCalculator';
import CartSorter from './CartSorter';
import CartViewModelBuilder from './CartViewModelBuilder';

export default class Cart {
    constructor(cart, onCartUpdated, settings, orderServiceType) {
        this.cart = cart;
        this.onCartUpdated = onCartUpdated;
        this.orderServiceType = orderServiceType;
        this.settings = settings;

        this.items = [];
        this.totalQuantity = 0;
        this.serviceCharge = 0;
        this.tax = 0;
        this.totalAmountIncTax = 0;
        this.totalAmountExTax = 0;
        this.netTotal = 0;
        this.finalTotal = 0;
        this.roundingAdjustment = 0;

        this.cartCalculator = new CartCalculator(settings, (values) => Object.assign(this, values));
        this.cartSorter = new CartSorter();
        this.cartViewModelBuilder = new CartViewModelBuilder();

        this._onIntialized();
    }

    // Cart methods
    getTotalQuantityForMenuItem(menuItemGuid) {
        // Get CartItem total quantity for a MenuItem
        return this.items
            .filter(x => x.menuItemGuid === menuItemGuid)
            .reduce((total, item) => total + item.quantity, 0);
    }

    findCartItem(cartItemId) {
        // Find CartItem using CartItemId
        return this.items.find(x => x.cartItemId === cartItemId);
    }

    hasAnyItems() {
        // Return true if has any items
        return this.items.length > 0;
    }

    addCartItem(item) {
        // Add CartItem
        this.sortCartItemChildren(item);
        this.items = [...this.items, item];
        this.cartCalculator.calculateCartItemsSurcharge(this.items);
        this.cartCalculator.calculateSurcharge(this.items);
        this.onCartUpdated();
    }

    updateCartItem(item) {
        // Update CartItem
        const isCartItemFound = this.items.some(x => x.cartItemId === item.cartItemId);
        if (!isCartItemFound) return;

        this.sortCartItemChildren(item);
        this.items = this.items.map(x => x.cartItemId === item.cartItemId ? item : x);
        this.cartCalculator.calculateCartItemsSurcharge(this.items);
        this.cartCalculator.calculateSurcharge(this.items);
        this.onCartUpdated();
    }

    deleteCartItem(cartItemId) {
        // Delete CartItem
        this.items = this.items.filter(x => x.cartItemId !== cartItemId);
        this.cartCalculator.calculateCartItemsSurcharge(this.items);
        this.cartCalculator.calculateSurcharge(this.items);
        this.onCartUpdated();
    }

    clearCart() {
        // Clear all CartItems
        this.items = [];
        this.cartCalculator.calculateSurcharge(this.items);
        this.onCartUpdated();
    }

    applyChangeLogs(changeLogs) {
        let newItems = [...this.items];

        for (const changeLog of changeLogs) {
            if (changeLog.isDelete) {
                // Delete
                newItems = newItems.filter(x => x.cartItemId !== changeLog.cartItemId);
            } else {
                const itemIndex = newItems.findIndex(x => x.cartItemId === changeLog.cartItemId);
                this.sortCartItemChildren(changeLog.cartItem);

                if (itemIndex === -1) {
                    // Create
                    newItems = [...newItems, changeLog.cartItem];
                } else {
                    // Update
                    newItems.splice(itemIndex, 1, changeLog.cartItem);
                }
            }
        }

        this.items = newItems;
        this.cartCalculator.calculateCartItemsSurcharge(this.items);
        this.cartCalculator.calculateSurcharge(this.items);
        this.onCartUpdated();
    }

    // Calculator methods
    calculateFinalTotal(netTotal) {
        return this.cartCalculator.calculateFinalTotal(netTotal);
    }

    calculateCartItemSubTotal(cartItem) {
        this.cartCalculator.calculateCartItemSubTotal(cartItem);
    }

    calculateCartSetMealItemSubTotal(cartSetMealItem) {
        this.cartCalculator.calculateCartSetMealItemSubTotal(cartSetMealItem);
    }

    // Sorter methods
    sortCartItemChildren(cartItem) {
        this.cartSorter.sortCartItemChildren(cartItem);
    }

    sortModifierItems(modifierItems) {
        this.cartSorter.sortModifierItems(modifierItems);
    }

    // Builder methods
    buildCartItem(menuItem) {
        const cartItem = this.cartViewModelBuilder.buildCartItem(menuItem, this.orderServiceType);
        this.cartCalculator.calculateCartItemSubTotal(cartItem);

        return cartItem;
    }

    buildCartItemTakeAwayItem(takeAwayItem) {
        return this.cartViewModelBuilder.buildCartItemTakeAwayItem(takeAwayItem);
    }

    buildCartItemModifierItem(modifierCategory, modifierItem) {
        return this.cartViewModelBuilder.buildCartItemModifierItem(modifierCategory, modifierItem);
    }

    buildCartSetMealItem(setMealCategory, setMealItem, quantity) {
        return this.cartViewModelBuilder.buildCartSetMealItem(setMealCategory, setMealItem, quantity);
    }

    buildCartSetMealItemModifierItem(cartSetMealItem, modifierCategory, modifierItem) {
        return this.cartViewModelBuilder.buildCartSetMealItemModifierItem(cartSetMealItem, modifierCategory, modifierItem);
    }

    _onIntialized() {
        if (this.cart?.items) {
            for (const item of this.cart.items) {
                this.sortCartItemChildren(item);
            }

            this.items = [...this.cart.items];
        }

        if (this.settings) {
            this.cartCalculator.calculateCartItemsSurcharge(this.items);
            this.cartCalculator.calculateSurcharge(this.items);
        }
    }
}