import * as Api from 'utils/api';
import { buildApiUrl } from 'utils/session';

const basePath = 'order';

export function get() {
    const url = buildApiUrl(basePath);
    return Api.getData(url);
}

export function getChangeLogs(lastCartItemChangeLogId, lastUserSessionChangeLogId) {
    const url = buildApiUrl(`${basePath}/getchangelogs`, { lastCartItemChangeLogId, lastUserSessionChangeLogId });
    return Api.getData(url);
}

export function getJoinedUserCount(qrCodeId) {
    const url = buildApiUrl(`${basePath}/getjoinedusercount`, { qrCodeId });
    return Api.getData(url);
}

export function leaveUserSession() {
    const url = buildApiUrl(`${basePath}/leaveusersession`);
    return Api.postData(url);
}

export function startOrder(data) {
    const url = buildApiUrl(`${basePath}/startOrder`);
    return Api.postData(url, data).then(response => response.json());
}

export function addCartItem(data) {
    const url = buildApiUrl(`${basePath}/addcartitem`);
    return Api.postData(url, data).then(response => response.json());
}

export function updateCartItem(data) {
    const url = buildApiUrl(`${basePath}/updatecartitem`);
    return Api.postData(url, data).then(response => response.json());
}

export function updateCartItemQuantity(data) {
    const url = buildApiUrl(`${basePath}/updatecartitemquantity`);
    return Api.postData(url, data).then(response => response.json());
}

export function deleteCartItem(cartItemId) {
    const url = buildApiUrl(`${basePath}/deletecartitem`, { cartItemId });
    return Api.deleteData(url);
}

export function confirmOrder(data) {
    const url = buildApiUrl(`${basePath}/confirmorder`);
    return Api.postData(url, data).then(response => response.json());
}

export function makePayment(data) {
    const url = buildApiUrl(`${basePath}/makepayment`);
    return Api.postData(url, data).then(response => response.json());
}