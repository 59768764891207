import withSettings from 'hocs/withSettings';
import * as ComponentUtils from 'utils/component';
import SummaryItem from './SummaryItem';

export const SummaryType = {
    Cart: 'cart',
    Order: 'order',
    OrderedTabFooter: 'orderedTabFooter'
};

const Summary = ({ settings, summaryData, summaryType, withWrapper }) => {
    const { serviceChargeRate } = settings;

    const getSummaryItemData = () => {
        const getCartOrOrder = ({ totalQuantity, totalAmountExTax, finalTotal }) => [
            build({ title: 'Total Items', value: totalQuantity, isCurrencyType: false, useDefaultMarginTop: false }),
            build({ title: 'Subtotal (excl. tax)', value: totalAmountExTax }),
            ...getBase(summaryData),
            build({ title: 'Final Total', value: finalTotal, isEmphasis: true, useDefaultMarginTop: false })
        ];

        const getOrderedTabFooter = ({ totalAmountExTax }) => [
            build({ title: 'Subtotal (excl. tax)', value: totalAmountExTax, useDefaultMarginTop: false }),
            ...getBase(summaryData)
        ];

        const getBase = ({ tax, serviceCharge, roundingAdjustment }) => [
            build({ title: 'Tax', value: tax, visible: tax !== 0 }),
            build({ title: `Service Charge (${serviceChargeRate}%)`, value: serviceCharge, visible: serviceCharge !== 0 }),
            build({ title: 'Rounding Adjustment', value: roundingAdjustment }),
        ];

        const build = ({
            title,
            value,
            isCurrencyType = true,
            isEmphasis = false,
            useDefaultMarginTop = true,
            visible = true
        }) => ({
            title,
            value,
            isCurrencyType,
            isEmphasis,
            useDefaultMarginTop,
            visible
        });

        if (summaryType === SummaryType.Cart || summaryType === SummaryType.Order) {
            return getCartOrOrder(summaryData);
        } else if (summaryType === SummaryType.OrderedTabFooter) {
            return getOrderedTabFooter(summaryData);
        }
    };

    const content = (
        <>
            {getSummaryItemData()
                .filter(x => x.visible)
                .map(x => <SummaryItem key={x.title} {...x} />)
            }
        </>
    );

    return withWrapper ? withWrapper(content) : content;
};

const hocs = [
    withSettings
];

export default ComponentUtils.compose(hocs)(Summary);