import { Component } from 'react';
import UserDeviceSettingsContext from 'contexts/UserDeviceSettingsContext';
import { getDisplayName } from 'utils/component';

export default function withUserDeviceSettings(WrappedComponent) {
    class WithUserDeviceSettings extends Component {
        render() {
            const { isPageVisible, isNetworkOnline } = this.context;

            return (
                <WrappedComponent
                    isPageVisible={isPageVisible}
                    isNetworkOnline={isNetworkOnline}
                    {...this.props}
                />
            );
        }
    }

    WithUserDeviceSettings.displayName = `WithUserDeviceSettings(${getDisplayName(WrappedComponent)})`;
    WithUserDeviceSettings.contextType = UserDeviceSettingsContext;

    return WithUserDeviceSettings;
}