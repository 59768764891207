// This exception should be thrown when an "impossible" condition is detected.
// If this exception occurs, it indicates a bug in the system that needs to be fixed.
class FailFastException extends Error {
    constructor(message) {
        super(message);
        this.name = 'FailFastException';

        // Refer to https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, FailFastException);
        }
    }

    static NotNull(name) {
        return new FailFastException(`${name} cannot be null or undefined.`);
    }
}

export default FailFastException;