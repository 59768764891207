import { Component } from 'react';
import * as OrderApi from 'apis/orderApi';
import OrderContext from 'contexts/OrderContext';
import SettingsContext from 'contexts/SettingsContext';
import * as HomePageErrorCodes from 'pages/Home/data/errorCode';
import { getDisplayName } from 'utils/component';
import * as ErrorMessage from 'utils/errorMessage';
import * as GuidUtils from 'utils/guidUtils';
import * as RoutesUtils from 'utils/routesUtils';
import { pageNames, pagePaths } from 'utils/routes';
import * as Session from 'utils/session';

export default function withOrderService(WrappedComponent) {
    class WithOrderService extends Component {
        render() {
            const { orderSessionData, cart } = this.context;

            return (
                <SettingsContext.Consumer>
                    {({ settings }) => (
                        <WrappedComponent
                            orderSessionData={orderSessionData}
                            cart={cart}
                            orderService={new OrderService(this.context, settings)}
                            {...this.props}
                        />
                    )}
                </SettingsContext.Consumer>
            );
        }
    }

    WithOrderService.displayName = `withOrderService(${getDisplayName(WrappedComponent)})`;
    WithOrderService.contextType = OrderContext;

    return WithOrderService;
}

class OrderService {
    constructor(context, settings) {
        this.context = context;
        this.settings = settings;
    }

    getOrders() {
        return new Promise((resolve, reject) => {
            OrderApi.get()
                .then(result => {
                    resolve(result);
                })
                .catch(error => {
                    reject(ErrorMessage.fetchError(error));
                });
        });
    }

    getJoinedUserCount() {
        const { orderSessionData } = this.context;
        const { qrCode } = orderSessionData;

        return new Promise((resolve, reject) => {
            OrderApi.getJoinedUserCount(qrCode)
                .then(result => {
                    resolve(result);
                })
                .catch(error => {
                    reject(ErrorMessage.fetchError(error));
                });
        });
    }

    leaveUserSession() {
        return new Promise((resolve, reject) => {
            OrderApi.leaveUserSession()
                .then(() => {
                    resolve();
                })
                .catch(error => {
                    reject(ErrorMessage.fetchError(error));
                });
        });
    }

    startOrder(shareCart, isHost, setAllowNavigation, options) {
        const { orderSessionData } = this.context;
        const { qrCode } = orderSessionData;

        return new Promise((resolve, reject) => {
            const input = {
                qrCodeId: qrCode,
                shareCart,
                isHost
            };

            const userId = Session.getUserId();

            if (userId === null || !GuidUtils.isValid(userId)) {
                Session.setUserId(GuidUtils.generate());
            }

            OrderApi.startOrder(input)
                .then(result => {
                    if (result.isQRCodeInvalid) {
                        RoutesUtils.redirectToPageByPath(pagePaths.QRCodeInvalid);
                    } else if (result.isQRCodeExpired) {
                        RoutesUtils.redirectToPageByName(pageNames.Home, { errorCode: HomePageErrorCodes.QRCodeExpired });
                    } else if (!result.isShareCartSessionFound) {
                        // Note : Start order successfully, ready to navigate user to Menu page
                        this.context.startOrder(result, setAllowNavigation, options);
                    }

                    resolve(result);
                })
                .catch(error => {
                    reject(ErrorMessage.fetchError(error));
                });
        });
    }

    addCartItem(cartItemInput) {
        const { cart } = this.context;
        const { enableTax, defaultTaxCode, defaultTaxRate } = this.settings;

        cart.calculateCartItemSubTotal(cartItemInput);

        const input = {
            cartItem: cartItemInput,
            enableTax,
            defaultTaxCode,
            defaultTaxRate
        };

        return new Promise((resolve, reject) => {
            OrderApi.addCartItem(input)
                .then(cartItem => {
                    cart.addCartItem(cartItem);
                    resolve();
                })
                .catch(error => {
                    reject(ErrorMessage.fetchError(error));
                });
        });
    }

    updateCartItem(cartItemInput) {
        const { cart } = this.context;
        const { enableTax, defaultTaxCode, defaultTaxRate } = this.settings;

        cart.calculateCartItemSubTotal(cartItemInput);

        const input = {
            cartItem: cartItemInput,
            enableTax,
            defaultTaxCode,
            defaultTaxRate
        };

        return new Promise((resolve, reject) => {
            OrderApi.updateCartItem(input)
                .then(cartItem => {
                    cart.updateCartItem(cartItem);
                    resolve();
                })
                .catch(error => {
                    reject(ErrorMessage.cartItemError(error));
                });
        });
    }

    updateCartItemQuantity(cartItemId, isAdd) {
        const { cart } = this.context;

        const input = {
            cartItemId,
            isAdd
        };

        return new Promise((resolve, reject) => {
            OrderApi.updateCartItemQuantity(input)
                .then(cartItem => {
                    if (cartItem.quantity === 0) {
                        cart.deleteCartItem(cartItem.cartItemId);
                    } else {
                        cart.updateCartItem(cartItem);
                    }
                    resolve();
                })
                .catch(error => {
                    reject(ErrorMessage.cartItemError(error));
                });
        })
    }

    deleteCartItem(cartItemId) {
        const { cart } = this.context;

        return new Promise((resolve, reject) => {
            OrderApi.deleteCartItem(cartItemId)
                .then(() => {
                    cart.deleteCartItem(cartItemId);
                    resolve();
                })
                .catch(error => {
                    reject(ErrorMessage.cartItemError(error));
                });
        });
    }

    confirmOrder(payLaterAtCounter, paymentGatewayChannelId, billingEmail, billingName, billingContact) {
        const { cart } = this.context;
        const { roundingOption, serviceChargeAndTaxInclusive, enableServiceCharge, serviceChargeRate, enableTax } = this.settings;

        const input = {
            roundingOption,
            serviceChargeAndTaxInclusive,
            enableServiceCharge,
            serviceChargeRate,
            enableTax,
            payLaterAtCounter,
            paymentGatewayChannelId,
            billingEmail,
            billingName,
            billingContact
        };

        return new Promise((resolve, reject) => {
            OrderApi.confirmOrder(input)
                .then(result => {
                    cart.clearCart();
                    resolve(result);
                })
                .catch(error => {
                    reject(ErrorMessage.fetchError(error));
                });
        });
    }

    makePayment(orderId, payLaterAtCounter, paymentGatewayChannelId, billingEmail, billingName, billingContact) {
        const input = {
            orderId,
            payLaterAtCounter,
            paymentGatewayChannelId,
            billingEmail,
            billingName,
            billingContact
        };

        return new Promise((resolve, reject) => {
            OrderApi.makePayment(input)
                .then(result => {
                    resolve(result);
                })
                .catch(error => {
                    reject(ErrorMessage.fetchError(error));
                });
        });
    }
}