import classNames from 'classnames';

const Badge = ({ className, style, children, ...rest }) => {
    const classes = classNames('fs-6 px-2 py-1', className);

    return (
        <span
            className={classes}
            style={{
                width: 'fit-content',
                background: 'var(--grey-100)',
                border: '1px solid var(--grey-400)',
                borderRadius: '3px',
                ...style
            }}
            {...rest}
        >
            {children}
        </span>
    );
};

export default Badge;