import withSettings from 'hocs/withSettings';
import * as ComponentUtils from 'utils/component';
import * as NumberUtils from 'utils/numberUtils';

const SummaryItem = ({ settings, title, value, isCurrencyType, isEmphasis, useDefaultMarginTop }) => {
    const { localCurrencySymbol, currencyFormat } = settings;

    return (
        <div className={`d-flex ${isEmphasis ? 'fw-bold' : 'fs-5'} ${useDefaultMarginTop ? 'mt-1' : 'mt-3'}`}>
            <span className={`col-8 ${isEmphasis ? '' : 'text-muted'}`}>
                {title}
            </span>
            <span className="col-4 text-end">
                {isCurrencyType
                    ? `${localCurrencySymbol} ${NumberUtils.formatNumber(value, currencyFormat)}`
                    : value
                }
            </span>
        </div>
    );
};

const hocs = [
    withSettings
];

export default ComponentUtils.compose(hocs)(SummaryItem);