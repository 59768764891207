import EmptyLine from 'components/EmptyLine';
import MenuItemImage from 'components.special/MenuItemImage';
import MenuItemName from 'components.special/MenuItemName';
import MenuItemPrice from 'components.special/MenuItemPrice';
import ItemUIRenderer from '../../utils/itemUIRenderer';

const OrderItemPanel = ({ orderItem }) => {
    const itemUIRenderer = ItemUIRenderer.createFromOrder(orderItem);

    return (
        <div className="d-flex flex-column px-3">
            {orderItem.quantity !== orderItem.originalQuantity &&
                <div
                    className="d-flex align-items-center justify-content-center fs-6 p-1 mt-3"
                    style={{
                        background: '#fff6e2',
                        borderRadius: '6px',
                        fontWeight: 600
                    }}
                >
                    {orderItem.quantity === 0
                        ? 'Cancelled by cashier.'
                        : `Qty changed by cashier from ${orderItem.originalQuantity} to ${orderItem.quantity}.`
                    }
                </div>
            }

            <div className="d-flex mt-3">
                <MenuItemImage
                    className="border"
                    style={{ borderRadius: '6px' }}
                    imageSrc={orderItem.image}
                    name={orderItem.name}
                    width='70px'
                    height='70px'
                    isLazyLoading
                />

                <div className="flex-grow-1 d-flex flex-column mx-3">
                    <div className="d-flex fs-5 fw-bold">
                        <span>
                            <MenuItemName name={orderItem.name} displayCode={orderItem.displayCode} />
                        </span>
                    </div>

                    {itemUIRenderer.renderCustomizeContents()}
                </div>

                <div className="col-2 d-flex flex-column text-end">
                    <MenuItemPrice
                        as="span"
                        className="fs-5"
                        value={orderItem.subTotal}
                        isCurrencyFormat
                    />
                    <span className="text-primary fw-bold mt-auto ms-2">
                        {`x${orderItem.quantity}`}
                    </span>
                </div>
            </div>

            <EmptyLine className="mt-3" />
        </div>
    );
};

export default OrderItemPanel;