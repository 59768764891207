import withOrderService from 'hocs/withOrderService';
import * as ComponentUtils from 'utils/component';
import Carts from './Carts';
import SectionWrapper from './SectionWrapper';
import AlertBanner from '../Shared/AlertBanner';
import EmptyItem from '../Shared/EmptyItem';
import Summary, { SummaryType } from '../Shared/Summary';

const InCartTab = ({ cart, closeDialog }) => (
    <>
        {cart.hasAnyItems()
            ? <>
                <AlertBanner>Check items before placing your order.</AlertBanner>
                <Carts />
                <Summary
                    summaryData={cart}
                    summaryType={SummaryType.Cart}
                    withWrapper={content =>
                        <SectionWrapper iconName="fas fa-cart-plus" iconLabel="Summary">
                            {content}
                        </SectionWrapper>
                    }
                />
            </>
            : <EmptyItem
                title="Oops... Your cart is empty"
                description="Go back to menu to checkout some items."
                imageSrc="/img/empty-cart-logo.png"
                closeDialog={closeDialog}
            />
        }
    </>
);

const hocs = [
    withOrderService
];

export default ComponentUtils.compose(hocs)(InCartTab);