import React from 'react';
import Badge from 'components/Badge';
import MenuItemTakeAway from 'components.special/MenuItemTakeAway';
import * as ServiceTypeConstants from 'data/serviceTypeConstant';
import ItemModelMapper from './itemModelMapper';

// CX-TODO : OrderedTab, allow the detail UI to expand to the end? else the space is so small
export default class ItemUIRenderer {
    constructor(item, isOrderedTab) {
        const itemModelMapper = new ItemModelMapper(item, isOrderedTab);

        this.data = itemModelMapper.map();

        this.renderCustomizeContents = this.renderCustomizeContents.bind(this);
    }

    static createFromCart(item) {
        return new ItemUIRenderer(item, false);
    }

    static createFromOrder(item) {
        return new ItemUIRenderer(item, true);
    }

    renderCustomizeContents() {
        const customizeContents = [];

        const badges = [];
        this._buildTakeAwayContent(badges);
        this._buildMenuItemModifierItemContents(badges);

        if (badges.length > 0) {
            customizeContents.push(
                <div key="badges" className="d-flex flex-wrap gap-2">
                    {badges}
                </div>
            );
        }

        this._buildSetMealItemContents(customizeContents);
        this._buildRemarkContent(customizeContents);

        return customizeContents.length > 0
            ? <div className="d-flex flex-column gap-2 mt-2">
                {customizeContents}
            </div>
            : null;
    }

    _buildTakeAwayContent(badges) {
        if (this.data.serviceType === ServiceTypeConstants.TakeAway) {
            badges.push(
                <Badge key="take-away">
                    <MenuItemTakeAway name={this.data.takeAwayItem?.name} />
                </Badge>
            );
        }
    }

    _buildMenuItemModifierItemContents(badges) {
        for (const modifierItem of this.data.modifierItems) {
            badges.push(
                <Badge key={modifierItem.modifierItemId}>
                    {modifierItem.description}
                </Badge>
            );
        }
    }

    _buildSetMealItemContents(customizeContents) {
        for (const setMealItem of this.data.setMealItems) {
            customizeContents.push(
                <React.Fragment key={setMealItem.setMealItemId}>
                    <div className="d-flex align-items-center text-muted fs-5">
                        <span className="me-1">{setMealItem.name}</span>
                        <span>{`x${setMealItem.quantity}`}</span>
                    </div>

                    {setMealItem.modifierItems.length > 0 &&
                        <div className="d-flex flex-wrap gap-2">
                            {setMealItem.modifierItems.map(x =>
                                <Badge key={x.modifierItemId}>
                                    {x.description}
                                </Badge>
                            )}
                        </div>
                    }
                </React.Fragment>
            );
        }
    }

    _buildRemarkContent(customizeContents) {
        if (this.data.remark) {
            customizeContents.push(
                <div
                    key="remark"
                    className="d-flex fs-6"
                    style={{ overflowWrap: 'anywhere' }}
                >
                    {`"${this.data.remark}"`}
                </div>
            );
        }
    }
}