import MenuItemImage from 'components.special/MenuItemImage';
import MenuItemName from 'components.special/MenuItemName';
import MenuItemPrice from 'components.special/MenuItemPrice';
import MenuItemQuantityPanel from 'components.special/MenuItemQuantityPanel';
import withBlockUI from 'hocs/withBlockUI';
import withOrderService from 'hocs/withOrderService';
import { withMenuItemFunction } from 'ui/MenuItem';
import * as ComponentUtils from 'utils/component';
import * as EventUtils from 'utils/eventUtils';
import * as Toast from 'utils/toast';
import * as MessageBox from 'utils/messageBox';
import ItemUIRenderer from '../../utils/itemUIRenderer';

const CartItemPanel = ({ orderService, blockUI, unblockUI, cartItem, updateItem }) => {
    const itemUIRenderer = ItemUIRenderer.createFromCart(cartItem);

    const onPanelClick = () => {
        updateItem(cartItem.cartItemId)
            .then(e => {
                if (e.actionMessage) {
                    Toast.showSuccessMessage(e.actionMessage);
                }
            })
            .catch(errorMessage => {
                Toast.showErrorMessage(errorMessage);
            });
    };

    const onQuantityChange = (e, isAdd) => {
        EventUtils.stopPropagation(e);

        if (!isAdd && cartItem.quantity === 1) {
            onDelete(e);
            return;
        }

        blockUI();

        orderService.updateCartItemQuantity(cartItem.cartItemId, isAdd)
            .then(() => {
                unblockUI();
            })
            .catch(errorMessage => {
                unblockUI();
                Toast.showErrorMessage(errorMessage);
            });
    };

    const onDelete = (e) => {
        EventUtils.stopPropagation(e);

        MessageBox.showConfirmMessage('Are you sure to delete this item?')
            .then(result => {
                if (result.confirm) {
                    blockUI();

                    orderService.deleteCartItem(cartItem.cartItemId)
                        .then(e => {
                            unblockUI();
                            Toast.showSuccessMessage('Item deleted successfully.');
                        })
                        .catch(errorMessage => {
                            unblockUI();
                            Toast.showErrorMessage(errorMessage);
                        });
                }
            });
    };

    return (
        <div
            className="d-flex flex-column gap-2 bg-white pt-3"
            onClick={onPanelClick}
        >
            {/* Detail Content */}
            <div className="d-flex">
                <MenuItemImage
                    className="border"
                    style={{ borderRadius: '6px' }}
                    imageSrc={cartItem.image}
                    name={cartItem.name}
                    width='80px'
                    height='80px'
                    isLazyLoading
                />

                <div className="flex-grow-1 d-flex flex-column ps-3">
                    <div className="d-flex fs-5">
                        <span className="flex-grow-1 fw-bold">
                            <MenuItemName name={cartItem.name} displayCode={cartItem.displayCode} />
                        </span>

                        <MenuItemPrice
                            as="span"
                            className="ps-1"
                            value={cartItem.subTotal}
                            isCurrencyFormat
                        />
                    </div>

                    {itemUIRenderer.renderCustomizeContents()}
                </div>
            </div>

            {/* Action Content */}
            <div className="d-flex align-items-center justify-content-end">
                <span
                    className="icon-icon-trash rounded-circle me-3"
                    style={{ padding: '10px', border: '1px solid var(--grey-400)' }}
                    onClick={onDelete}
                />

                <MenuItemQuantityPanel
                    value={cartItem.quantity}
                    handleAdd={e => onQuantityChange(e, true)}
                    handleMinus={e => onQuantityChange(e, false)}
                />
            </div>
        </div >
    );
};

const hocs = [
    withMenuItemFunction,
    withOrderService,
    withBlockUI
];

export default ComponentUtils.compose(hocs)(CartItemPanel);