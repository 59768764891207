import React from 'react';
import HintText from 'components/HintText';
import withOrderService from 'hocs/withOrderService';
import withSettings from 'hocs/withSettings';
import * as ComponentUtils from 'utils/component';
import * as DateTimeUtils from 'utils/dateTimeUtils';
import Orders from './Orders';
import AlertBanner from '../Shared/AlertBanner';
import EmptyItem from '../Shared/EmptyItem';

const OrderedTabInner = ({
    orderSessionData,
    settings,
    orders,
    lastSyncOrderTimeStamp,
    closeDialog,
    makePayment,
    syncOrders,
    forwardedRef }) => {

    const { useEPayment } = orderSessionData;
    const { allowPayLaterAtCounter, restaurantUseEPayment } = settings;

    return (
        <>
            {useEPayment && restaurantUseEPayment && orders.length > 0 &&
                <AlertBanner>
                    {allowPayLaterAtCounter
                        ? 'Order will be sent to counter immediately if paying at counter. For e-payment, order preparation starts after payment.'
                        : 'Order preparation begins after e-payment is completed.'
                    }
                </AlertBanner>
            }

            <HintText className="d-flex align-items-center justify-content-center py-3">
                <span>
                    {`Last Update ${lastSyncOrderTimeStamp ? DateTimeUtils.dateToString(lastSyncOrderTimeStamp, true) : ''}`}
                </span>

                <span
                    className="fas fa-sync-alt ms-2"
                    onClick={() => syncOrders(false, true)}
                    ref={forwardedRef}
                />
            </HintText>

            {orders.length > 0
                ? <Orders
                    orders={orders}
                    makePayment={makePayment}
                />
                : <EmptyItem
                    title="Ooops... You don't have any orders"
                    description="Go back to menu to checkout some items and confirm order."
                    imageSrc="/img/empty-order-logo.png"
                    closeDialog={closeDialog}
                />
            }
        </>
    );
};

const hocs = [
    withOrderService,
    withSettings
];

const OrderedTabWithHocs = ComponentUtils.compose(hocs)(OrderedTabInner);

const OrderedTab = React.forwardRef((props, ref) => <OrderedTabWithHocs {...props} forwardedRef={ref} />);

export default OrderedTab;