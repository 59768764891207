// Helper class to map CartItem / OrderItem to ItemModel
export default class ItemModelMapper {
    constructor(item, isOrderedTab) {
        this.item = item;
        this.isOrderedTab = isOrderedTab;

        this.map = this.map.bind(this);
    }

    map() {
        return {
            name: this.item.name,
            displayCode: this.item.displayCode,
            remark: this.item.remark,
            serviceType: this.item.serviceType,
            isSetMeal: !!this.item.menuSetMealGuid,
            takeAwayItem: this.item.takeAwayItem,
            ...(this.isOrderedTab
                ? this._mapFromOrderItem()
                : this._mapFromCartItem())
        };
    }

    _mapFromCartItem() {
        // Map CartItem to ItemModel
        return {
            modifierItems: this.item.modifierItems.map(x => ({
                modifierItemId: x.cartItemModifierItemId,
                description: x.description,
                quantity: x.quantity
            })),
            setMealItems: this.item.setMealItems.map(x => ({
                setMealItemId: x.cartSetMealItemId,
                name: x.name,
                quantity: x.quantity,
                modifierItems: x.modifierItems.map(y => ({
                    modifierItemId: y.cartSetMealItemModifierItemId,
                    description: y.description,
                    quantity: y.quantity
                }))
            }))
        };
    }

    _mapFromOrderItem() {
        // Map OrderItem to ItemModel
        return {
            modifierItems: this.item.modifierItems.map(x => ({
                modifierItemId: x.orderItemModifierItemId,
                description: x.description,
                quantity: x.quantity
            })),
            setMealItems: this.item.setMealItems.map(x => ({
                setMealItemId: x.orderSetMealItemId,
                name: x.name,
                quantity: x.quantity,
                modifierItems: x.modifierItems.map(y => ({
                    modifierItemId: y.orderSetMealItemModifierItemId,
                    description: y.description,
                    quantity: y.quantity
                }))
            }))
        };
    }
}