import { getPaymentChannel } from 'data/paymentChannelText';
import * as NumberUtils from 'utils/numberUtils';

export default class SettingValues {
    constructor(obj) {
        Object.assign(this, obj);
        this.calculateDerivedValues();
    }

    calculateDerivedValues() {
        this.currencyFormat = NumberUtils.getDecimalFormat(this.currencyDecimal, true);
        this.priceFormat = NumberUtils.getDecimalFormat(2, true);

        if (this.restaurantPaymentChannels) {
            this.restaurantPaymentChannels = this.restaurantPaymentChannels.filter(x => getPaymentChannel(x.paymentChannelId) !== null);
        }
    }
}