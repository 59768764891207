// Helper class for sorting CartItems children
export default class CartSorter {
    sortCartItemChildren(cartItem) {
        // Sort CartItem children
        if (cartItem.menuSetMealGuid) {
            this._sortSetMealItem(cartItem.setMealItems);

            for (const setMealItem of cartItem.setMealItems) {
                this.sortModifierItems(setMealItem.modifierItems);
            }
        } else {
            this.sortModifierItems(cartItem.modifierItems);
        }
    }

    sortModifierItems(modifierItems) {
        this._sort(modifierItems, [x => x.menuModifierCategorySeq, x => x.menuModifierItemSeq]);
    }

    _sortSetMealItem(setMealItems) {
        this._sort(setMealItems, [x => x.menuSetMealCategorySeq, x => x.menuSetMealItemSeq]);
    }

    _sort(items, selectors) {
        items.sort((a, b) => {
            for (const selector of selectors) {
                const aValue = selector(a);
                const bValue = selector(b);

                if (aValue < bValue) return -1;
                if (aValue > bValue) return 1;
            }

            return 0;
        })
    }
}