import Icon from 'components/Icon';

const SectionWrapper = ({ iconName, iconLabel, children, ...rest }) => {
    return (
        <div
            className="d-flex flex-column p-3"
            style={{ borderBottom: '8px solid var(--grey-100)' }}
            {...rest}
        >
            <Icon
                className="d-flex align-items-center text-primary fs-3"
                style={{ fontWeight: 600 }}
                name={iconName}
                label={iconLabel}
            />

            {children}
        </div>
    );
};

export default SectionWrapper;