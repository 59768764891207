import React from 'react';

const FloatingActionButton = React.forwardRef((_, ref) => (
    <div
        className="position-fixed"
        style={{
            maxWidth: 'var(--max-width)',
            margin: 'auto',
            left: 0,
            right: 0,
            bottom: '100px'
        }}
        ref={ref}
    >
        <span
            className="fas fa-chevron-circle-up position-absolute fs-1 me-3"
            style={{ right: 0, cursor: 'pointer' }}
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        />
    </div>
));

export default FloatingActionButton;