import React from 'react';
import classNames from 'classnames';

const MenuItemQuantityIcon = ({
    className,
    style,
    border,
    shadow,
    fill = false,
    minWidth = '25px',
    height = '25px',
    children }) => {

    const classes = classNames(
        'd-inline-flex align-items-center justify-content-center rounded-circle fs-5',
        {
            'border': border,
            'shadow': shadow,
            'text-white bg-primary': fill,
            'text-primary bg-white': !fill
        },
        className
    );

    return (
        <span
            className={classes}
            style={{
                fontWeight: 600,
                minWidth,
                height,
                ...style
            }}
        >
            {children}
        </span>
    );
}

export default MenuItemQuantityIcon;