import Dialog from 'components/Dialog';
import MenuItemImage from 'components.special/MenuItemImage';

const ImageDialog = ({ imageSrc, closeDialog }) => {
    return (
        <Dialog
            isOpen
            centered
            closeDialog={closeDialog}
        >
            <MenuItemImage
                className="w-100 h-100"
                style={{ objectFit: 'contain' }}
                imageSrc={imageSrc}
            />
        </Dialog>
    );
}

export default ImageDialog;