import * as Dialog from 'devextreme/ui/dialog';

// Application version
export function startCheckVersion() {
    setTimeout(checkVersion, 10000);
}

function checkVersion() {
    if (isVersionMismatch()) {
        showVersionUpdatedMessage();
    } else {
        startCheckVersion();
    }
}

function isVersionMismatch() {
    return window.APP_SETTINGS_Version && 
           window.GLOBAL_Version && 
           window.APP_SETTINGS_Version !== window.GLOBAL_Version;
}

function showVersionUpdatedMessage() {
    const dlg = Dialog.custom({
        showTitle: true,
        title: 'Version updated',
        dragEnabled: false,
        messageHtml: '<div style="max-width:500px">Please click Reload button below to reload.</div>',
        buttons: [{
            text: 'Reload',
            type: 'default',
            onClick: () => window.location.reload()
        }]
    });

    return dlg.show();
}