import MenuCategory from './MenuCategory';

const Menu = ({ menuCategories, viewMenuItem }) => (
    <>
        {menuCategories.map(x => (
            <MenuCategory
                key={x.menuCategoryGuid}
                menuCategory={x}
                viewMenuItem={viewMenuItem}
            />
        ))}
    </>
);

export default Menu;