import { Component } from 'react';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Image from 'components/Image';
import RestaurantOperatingHours from 'components.special/RestaurantOperatingHours';
import * as CartModeConstants from 'data/cartModeConstant';
import * as ServiceTypeConstants from 'data/serviceTypeConstant';
import { getServiceTypeText } from 'data/serviceTypeText';
import withBlockUI from 'hocs/withBlockUI';
import withOrderService from 'hocs/withOrderService';
import withOutlet from 'hocs/withOutlet';
import withRouter from 'hocs/withRouter';
import withSettings from 'hocs/withSettings';
import withCartModeFunction from 'ui/CartMode/hocs/withCartMode';
import * as Session from 'utils/session';
import * as ComponentUtils from 'utils/component';
import * as Toast from 'utils/toast';
import LandingPage from './LandingPage';
import * as ErrorCodes from '../data/errorCode';

class Page extends Component {
    constructor(props) {
        super(props);

        const { settings } = props;
        const { viewLandingPageImage } = settings;

        this.state = {
            isLandingPageShown: viewLandingPageImage ? Session.getIsLandingPageShown() : true
        };

        this.startOrder = this.startOrder.bind(this);
        this.doStartOrder = this.doStartOrder.bind(this);
    }

    componentDidMount() {
        if (!this.state.isLandingPageShown) {
            setTimeout(() => {
                this.setState({ isLandingPageShown: true });
                Session.setIsLandingPageShown();
            }, 4000);
        }
    }

    async startOrder() {
        const { orderSessionData, settings } = this.props;
        const { isQRCodeStatic, isPreview, orderServiceType } = orderSessionData;
        const { cartMode } = settings;

        let shareCart, isHost; // Note : IsHost is true during initial start order in "allow both" cart mode

        if (isPreview || orderServiceType === ServiceTypeConstants.TakeAway || isQRCodeStatic) {
            shareCart = false;
            isHost = false;
        } else if (cartMode === CartModeConstants.AllowShareCartOnly) {
            shareCart = true;
            isHost = false;
        } else if (cartMode === CartModeConstants.AllowIndividualCartOnly) {
            shareCart = false;
            isHost = false;
        } else if (cartMode === CartModeConstants.AllowBoth) {
            shareCart = true;
            isHost = true;
        }

        this.doStartOrder(shareCart, isHost);
    }

    async doStartOrder(shareCart, isHost) {
        const { setAllowNavigation, orderService } = this.props;

        this.props.blockUI();

        try {
            const result = await orderService.startOrder(shareCart, isHost, setAllowNavigation);
            this.props.unblockUI();

            if (result.isShareCartSessionFound) {
                shareCart = await this.props.updateCartMode();
                this.doStartOrder(shareCart, false);
            }
        } catch (errorMessage) {
            this.props.unblockUI();
            Toast.showErrorMessage(errorMessage);
        }
    }

    renderErrorCodeContent(urlErrorCode) {
        let errorMessage = '';

        if (urlErrorCode === ErrorCodes.RestaurantClosed) {
            errorMessage = 'The restaurant is now closed.';
        } else if (urlErrorCode === ErrorCodes.QRCodeExpired) {
            errorMessage = 'The QR Code is expired, please request a new QR Code from the merchant.';
        } else if (urlErrorCode === ErrorCodes.SessionClosed) {
            errorMessage = 'The table session is closed, please scan the QR Code again or request a new QR Code from the merchant.';
        }

        return (
            <div
                className="d-flex align-items-center justify-content-center fs-5 m-3 p-2"
                style={{
                    background: '#ffac0038',
                    color: '#b83909',
                    borderRadius: '6px'
                }}
            >
                {urlErrorCode === ErrorCodes.RestaurantClosed &&
                    <Image
                        className="col-3"
                        style={{ objectFit: 'contain' }}
                        imageSrc='/img/restaurant-closed-logo.png'
                        height='40px'
                    />
                }

                <span className={`flex-grow-1 ${urlErrorCode !== ErrorCodes.RestaurantClosed ? 'text-center' : 'ms-2'}`}>
                    {errorMessage}
                </span>
            </div>
        );
    }

    render() {
        const { location, orderSessionData, settings } = this.props;
        const { isLandingPageShown } = this.state;
        const { tableNo, orderServiceType } = orderSessionData;
        const { viewName, viewLogo, viewLandingPageImage, viewHomePageBackgroundImage } = settings;

        const urlParams = new URLSearchParams(location.search);
        const urlErrorCode = urlParams.get('errorCode');
        const canStartOrder = urlParams.size === 0 || !urlErrorCode;

        return (
            !isLandingPageShown && viewLandingPageImage
                ? <LandingPage />
                : <>
                    <div
                        className="position-relative"
                        style={{
                            height: '100vh',
                            // eslint-disable-next-line no-dupe-keys
                            height: '100svh',
                            backgroundImage: viewHomePageBackgroundImage
                                ? `url('${viewHomePageBackgroundImage}')`
                                : `url('/img/home-default-background.png')`,
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            backgroundSize: 'cover'
                        }}
                    >
                        <div
                            className="position-absolute p-5"
                            style={{
                                inset: 0,
                                margin: 'auto',
                                width: 'fit-content',
                                height: 'fit-content',
                                minWidth: 'calc(var(--max-width) / 3 * 2)'
                            }}
                        >
                            <Image
                                className="position-absolute w-100 h-100"
                                style={{ inset: 0, margin: 'auto' }}
                                imageSrc="/img/home-container.png"
                            />

                            <div className="position-relative d-flex flex-column">
                                <div className="text-center">
                                    <Image
                                        className="border rounded-circle"
                                        width='80px'
                                        height='80px'
                                        imageSrc={viewLogo ? viewLogo : '/img/home-default-restaurant-logo.png'}
                                    />
                                </div>

                                <span className="text-center fw-bold mt-2">{viewName}</span>

                                {orderServiceType &&
                                    <span className="text-center fs-5">{`(${getServiceTypeText(orderServiceType)})`}</span>
                                }

                                {tableNo &&
                                    <div className="d-flex align-items-center justify-content-center mt-2">
                                        <div className="px-2" style={{ background: '#fd9827', borderRadius: '6px' }}>
                                            <Image
                                                width='20px'
                                                height='20px'
                                                imageSrc='/img/table-no-logo.png'
                                            />

                                            <span className="text-white fw-bold fs-5 ms-2">{tableNo}</span>
                                        </div>
                                    </div>
                                }

                                <RestaurantOperatingHours className="m-3" />

                                {canStartOrder
                                    ? <Button
                                        className="mx-3 mb-2"
                                        primary
                                        onClick={this.startOrder}
                                    >
                                        <Icon
                                            name='fas fa-arrow-right'
                                            label='Start Order'
                                            showIconFirst={false}
                                        />
                                    </Button>
                                    : this.renderErrorCodeContent(urlErrorCode)
                                }
                            </div>
                        </div>
                    </div>

                    {/* Footer */}
                    <div
                        className="fixed-bottom d-flex align-items-center justify-content-center text-white bg-dark fs-6 py-1"
                        style={{
                            maxWidth: 'var(--max-width)',
                            left: 0,
                            right: 0,
                            margin: '0 auto'
                        }}
                    >
                        Powered by AutoCount
                    </div>
                </>
        );
    }
}

const hocs = [
    withBlockUI,
    withOutlet,
    withRouter,
    withOrderService,
    component => withSettings(component, true),
    withCartModeFunction
];

export default ComponentUtils.compose(hocs)(Page);