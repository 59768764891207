import MenuItemImage from 'components.special/MenuItemImage';
import MenuItemName from 'components.special/MenuItemName';
import MenuItemQuantityIcon from 'components.special/MenuItemQuantityIcon';
import withOrderService from 'hocs/withOrderService';
import * as ComponentUtils from 'utils/component';
import MenuItemPriceTag from './MenuItemPriceTag';

const MenuTrendingItem = ({ cart, menuItem, isLeftMenuItem, viewMenuItem }) => {
    const { menuItemGuid, name, image, displayCode } = menuItem;

    const qty = cart.getTotalQuantityForMenuItem(menuItemGuid);

    return (
        <div
            key={menuItemGuid}
            className={`d-flex flex-column pt-3 ${isLeftMenuItem ? '' : 'ms-auto'}`}
            style={{ flexBasis: '45%' }}
            onClick={() => viewMenuItem(menuItemGuid)}
        >
            <div
                className="position-relative w-100"
                style={{ height: 0, paddingBottom: '100%' }}
            >
                <MenuItemImage
                    className="position-absolute w-100 h-100"
                    style={{ borderRadius: '5px', top: 0, left: 0 }}
                    imageSrc={image}
                    name={name}
                    isLazyLoading
                />

                {qty > 0 &&
                    <MenuItemQuantityIcon
                        className="position-absolute"
                        style={{ top: '-10px', left: '-5px' }}
                        minWidth='30px'
                        height='30px'
                        border
                        shadow
                    >
                        {qty}
                    </MenuItemQuantityIcon>
                }
            </div>

            <span className="fs-5 mt-1">
                <MenuItemName name={name} displayCode={displayCode} />
            </span>

            <MenuItemPriceTag menuItem={menuItem} />
        </div>
    );
};

const hocs = [
    withOrderService
];

export default ComponentUtils.compose(hocs)(MenuTrendingItem);