import Dialog from 'components/Dialog';
import ImageCarousel from 'components/ImageCarousel';

const BannerDialog = ({ banners, closeDialog }) => {
    return (
        <Dialog
            isOpen
            centered
            closeDialog={closeDialog}
        >
            <ImageCarousel
                data={banners}
                style={{ height: '60vh' }}
            />
        </Dialog>
    );
}

export default BannerDialog;