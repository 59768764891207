import { Component } from 'react';
import withBlockUI from 'hocs/withBlockUI';
import withOrderService from 'hocs/withOrderService';
import withUser from 'hocs/withUser';
import * as ComponentUtils from 'utils/component';
import CartModeDialog from '../components/CartModeDialog';
import * as CartModeConstants from '../data/cartModeConstant';

class CartModeFunction extends Component {
    constructor(props) {
        super(props);

        this.state = {
            cartMode: null,
            formMode: {},
            joinedUserCount: 0,
            showCartModeDialog: false
        };

        this.openCartModeDialog = this.openCartModeDialog.bind(this);
        this.closeCartModeDialog = this.closeCartModeDialog.bind(this);

        this.readCartMode = this.readCartMode.bind(this);
        this.updateCartMode = this.updateCartMode.bind(this);

        this.saveRecord = this.saveRecord.bind(this);
    }

    setReadMode() {
        this.setState({ formMode: { isRead: true } });
    }

    setUpdateMode() {
        this.setState({ formMode: { isUpdate: true } });
    }

    openCartModeDialog() {
        this.setState({ showCartModeDialog: true });
    }

    closeCartModeDialog() {
        this.setState({ showCartModeDialog: false });
    }

    readCartMode() {
        const { orderSessionData, user } = this.props;
        const { shareCart } = orderSessionData;

        this.setReadMode();

        this.setState({
            cartMode: shareCart ? CartModeConstants.ShareCart : CartModeConstants.IndividualCart,
            joinedUserCount: user.getJoinedUserItemCount()
        });

        this.openCartModeDialog();
    }

    updateCartMode() {
        const { orderService } = this.props;

        this.setUpdateMode();

        orderService.getJoinedUserCount()
            .then(result => {
                this.setState({ joinedUserCount: result });
                this.openCartModeDialog();
            })
            .catch(errorMessage => {
                this.props.onActionError(errorMessage);
            });
    }

    saveRecord(cartMode) {
        const { formMode } = this.state;

        if (formMode.isUpdate) {
            const shareCart = cartMode === CartModeConstants.ShareCart;
            this.props.onActionCompleted(shareCart);
        }
    }

    render() {
        const { cartMode, formMode, joinedUserCount, showCartModeDialog } = this.state;

        return (
            <>
                {this.props.children({ readCartMode: this.readCartMode, updateCartMode: this.updateCartMode })}

                {showCartModeDialog &&
                    <CartModeDialog
                        cartMode={cartMode}
                        joinedUserCount={joinedUserCount}
                        isUpdate={formMode.isUpdate}
                        closeDialog={this.closeCartModeDialog}
                        onCartModeSelected={this.saveRecord}
                    />
                }
            </>
        )
    }
}

const hocs = [
    withBlockUI,
    withOrderService,
    withUser
];

const CartModeFunctionWithHocs = ComponentUtils.compose(hocs)(CartModeFunction);

function withCartModeFunction(WrappedComponent) {
    // eslint-disable-next-line react/no-multi-comp
    class WithCartModeFunction extends Component {
        constructor(props) {
            super(props);

            this.state = {
                onActionCompleted: null,
                onActionError: null
            };
        }

        transformToPromise(action) {
            return new Promise((resolve, reject) => {
                this.setState({
                    onActionCompleted: resolve,
                    onActionError: reject
                }, () => action());
            });
        }

        render() {
            return (
                <CartModeFunctionWithHocs
                    {...this.state}
                    {...this.props}
                >
                    {({ readCartMode, updateCartMode }) => (
                        <WrappedComponent
                            readCartMode={() => this.transformToPromise(() => readCartMode())}
                            updateCartMode={() => this.transformToPromise(() => updateCartMode())}
                            {...this.props}
                        />
                    )}
                </CartModeFunctionWithHocs>
            );
        }
    }

    WithCartModeFunction.displayName = `WithCartModeFunction(${ComponentUtils.getDisplayName(WrappedComponent)})`;

    return WithCartModeFunction;
}

export default withCartModeFunction;