import * as UserSessionChangeLogTypeConstants from 'data/userSessionChangeLogTypeConstant';
import * as DateTimeUtils from 'utils/dateTimeUtils';
import * as Toast from 'utils/toast';

export default class User {
    constructor(userId, onUserUpdated) {
        this.items = [];
        this.onUserUpdated = onUserUpdated;
        this.userId = userId;
    }

    getJoinedUserItemCount() {
        return this.items.filter(x => x.userSessionChangeLogType === UserSessionChangeLogTypeConstants.Join).length;
    }

    getCurrentUserItemSeq() {
        // Get current UserItem seq
        const item = this.findUserItem(this.userId);
        return item ? item.seq : 0;
    }

    findUserItem(userId) {
        // Find UserItem using userId
        return this.items.find(x => x.userId === userId);
    }

    applyChangeLogs(changeLogs, isInitialLoad) {
        // Apply ChangeLogs
        const changeLogsForToast = [];

        for (const changeLog of changeLogs) {
            const changeLogItem = changeLog.item;
            const item = this.items.find(x => x.userId === changeLogItem.userId);

            if (item === undefined) {
                // - Create
                this.items.push({ ...changeLogItem });

                if (this._shouldDisplayToast(isInitialLoad, changeLogItem)) {
                    // - Add ChangeLog to toast queue
                    changeLogsForToast.push(changeLog);
                }
            } else if (item.userSessionChangeLogType !== changeLogItem.userSessionChangeLogType) {
                // - Update
                item.userSessionChangeLogType = changeLogItem.userSessionChangeLogType;

                if (this._shouldDisplayToast(isInitialLoad, changeLogItem)) {
                    // - Add ChangeLog to toast queue
                    changeLogsForToast.push(changeLog);
                }
            }
        }

        this._sort(this.items);
        this._showToast(changeLogsForToast);
        this.onUserUpdated();
    }

    _sort(items) {
        items.sort((a, b) => DateTimeUtils.compareDateTime(a.createdTimeStamp, b.createdTimeStamp));

        for (const [index, item] of items.entries()) {
            item.seq = index + 1;
        }
    }

    _showToast(changeLogs) {
        for (const changeLog of changeLogs) {
            const changeLogItem = changeLog.item;
            const item = this.items.find(x => x.userId === changeLogItem.userId);

            if (changeLogItem.userSessionChangeLogType === UserSessionChangeLogTypeConstants.Join) {
                Toast.showSuccessMessage(`Guest ${item.seq} joined.`);
            } else if (changeLogItem.userSessionChangeLogType === UserSessionChangeLogTypeConstants.Leave) {
                Toast.showWarningMessage(`Guest ${item.seq} left.`);
            }
        }
    }

    _shouldDisplayToast(isInitialLoad, changeLogItem) {
        if (isInitialLoad) return false;
        if (changeLogItem.userId === this.userId) return false;

        return true;
    }
}