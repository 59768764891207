import HintText from 'components/HintText';
import MenuItem from './MenuItem';
import MenuTrendingItem from './MenuTrendingItem';

const MenuCategory = ({ menuCategory, viewMenuItem }) => {
    const { menuCategoryGuid, name, description, isTrending, items } = menuCategory;

    const menuItems = isTrending
        ? items.filter(x => !x.isOutOfStock) // Note : Hide trending out of stock items
        : items;

    return (
        <div
            id={menuCategoryGuid}
            className="d-flex flex-column bg-white px-3 pt-3"
            style={{ borderBottom: '8px solid var(--grey-100)' }}
        >
            <div className="d-flex align-items-center">
                {isTrending &&
                    <span className="fas fa-fire me-2" style={{ color: '#e04006' }} />
                }

                <span className="fs-2 fw-bold">{name}</span>
            </div>

            <HintText>{description}</HintText>

            {isTrending
                ? <div className="d-flex flex-wrap mb-3">
                    {menuItems.map((x, index) =>
                        <MenuTrendingItem
                            key={x.menuItemGuid}
                            menuItem={x}
                            isLeftMenuItem={index % 2 === 0}
                            viewMenuItem={viewMenuItem}
                        />
                    )}
                </div>
                : <>
                    {menuItems.map((x, index) =>
                        <MenuItem
                            key={x.menuItemGuid}
                            menuItem={x}
                            isLastMenuItem={index === menuItems.length - 1}
                            viewMenuItem={viewMenuItem}
                        />
                    )}
                </>
            }
        </div>
    );
};

export default MenuCategory;