import * as ServiceTypeConstants from 'data/serviceTypeConstant';
import * as GuidUtils from 'utils/guidUtils';

// Helper class for building view model
export default class CartViewModelBuilder {
    buildCartItem(menuItem, orderServiceType) {
        // Build CartItem from MenuItem
        const isSetMeal = !!menuItem.setMeal;

        const result = {
            cartItemId: GuidUtils.generate(),
            menuItemGuid: menuItem.menuItemGuid,
            menuSetMealGuid: isSetMeal ? menuItem.setMeal.menuSetMealGuid : null,
            name: menuItem.name,
            description: menuItem.description,
            itemCode: menuItem.itemCode,
            displayCode: menuItem.displayCode,
            serviceType: orderServiceType,
            taxCode: menuItem.taxCode,
            taxRate: menuItem.taxRate,
            price: menuItem.price,
            image: menuItem.image,
            quantity: 1,
            remark: null,
            takeAwayItem: null,
            modifierItems: [],
            setMealItems: []
        };

        // - Assign TakeAwayItem if service type is take away and MenuItem has TakeAwayItem
        if (orderServiceType === ServiceTypeConstants.TakeAway && menuItem.takeAwayItem) {
            result.takeAwayItem = this.buildCartItemTakeAwayItem(menuItem.takeAwayItem);
        }

        // - Assign default Modifier / SetMealItem
        if (!isSetMeal) {
            this._assignDefaultModifierItem(menuItem.modifierCategories, result);
        } else {
            this._assignDefaultSetMealItem(menuItem.setMeal.categories, result);
        }

        return result;
    }

    buildCartItemTakeAwayItem(takeAwayItem) {
        // Build CartItemTakeAwayItem
        return {
            cartItemTakeAwayItemId: GuidUtils.generate(),
            menuTakeAwayItemGuid: takeAwayItem.menuTakeAwayItemGuid,
            name: takeAwayItem.name,
            itemCode: takeAwayItem.itemCode,
            taxCode: takeAwayItem.taxCode,
            taxRate: takeAwayItem.taxRate,
            price: takeAwayItem.price
        };
    }

    buildCartItemModifierItem(modifierCategory, modifierItem) {
        // Build CartItemModifierItem
        return {
            cartItemModifierItemId: GuidUtils.generate(),
            menuModifierItemGuid: modifierItem.menuModifierItemGuid,
            menuModifierCategoryGuid: modifierCategory.menuModifierCategoryGuid,
            description: modifierItem.description,
            image: modifierItem.image,
            itemCode: modifierItem.itemCode,
            surcharge: modifierItem.surcharge,
            subTotal: modifierItem.surcharge,
            quantity: 1,
            menuModifierCategorySeq: modifierCategory.seq,
            menuModifierItemSeq: modifierItem.seq,
        };
    }

    buildCartSetMealItem(setMealCategory, setMealItem, quantity) {
        // Build CartSetMealItem
        const result = {
            cartSetMealItemId: GuidUtils.generate(),
            menuSetMealCategoryGuid: setMealCategory.menuSetMealCategoryGuid,
            menuSetMealItemGuid: setMealItem.menuSetMealItemGuid,
            name: setMealItem.name,
            itemCode: setMealItem.itemCode,
            taxCode: setMealItem.taxCode,
            taxRate: setMealItem.taxRate,
            price: setMealItem.price,
            image: setMealItem.image,
            quantity: quantity ?? 1, // Set default quantity as 1 if is null / undefined
            menuSetMealCategorySeq: setMealCategory.seq,
            menuSetMealItemSeq: setMealItem.seq,
            modifierItems: []
        };

        // - Assign default Modifier
        this._assignDefaultModifierItem(setMealItem.modifierCategories, null, result);

        return result;
    }

    buildCartSetMealItemModifierItem(cartSetMealItem, modifierCategory, modifierItem) {
        // Build CartSetMealItemModifierItem
        return {
            cartSetMealItemModifierItemId: GuidUtils.generate(),
            cartSetMealItemId: cartSetMealItem.cartSetMealItemId,
            menuModifierItemGuid: modifierItem.menuModifierItemGuid,
            menuModifierCategoryGuid: modifierCategory.menuModifierCategoryGuid,
            description: modifierItem.description,
            image: modifierItem.image,
            itemCode: modifierItem.itemCode,
            surcharge: modifierItem.surcharge,
            subTotal: modifierItem.surcharge,
            quantity: 1,
            menuModifierCategorySeq: modifierCategory.seq,
            menuModifierItemSeq: modifierItem.seq
        };
    }

    _getDefaultSetMealItem(setMealCategory) {
        // Return default SetMealItem
        const hasDefaultItemForModifierCategory = (setMealItem) => {
            let isValid = true;

            for (const modifierCategory of setMealItem.modifierCategories) {
                const isItemRequired = !!modifierCategory.minQty;

                if (isItemRequired && (modifierCategory.minQty > 1 || !modifierCategory.items.some(x => x.isDefault && !x.isOutOfStock))) {
                    isValid = false;
                    break;
                }
            }

            return isValid;
        };

        // - Check has any default SetMealItem
        const defaultSetMealItem = setMealCategory.items.find(x => x.isDefault && !x.isOutOfStock);
        if (defaultSetMealItem && hasDefaultItemForModifierCategory(defaultSetMealItem)) {
            return defaultSetMealItem;
        }

        // - Check category has minQty & only one SetMealItem option available
        const isItemRequired = !!setMealCategory.minQty;
        const hasOneItemOptionOnly = setMealCategory.items.length === 1;
        const firstItemOption = setMealCategory.items[0];

        if (isItemRequired &&
            hasOneItemOptionOnly &&
            !firstItemOption.isOutOfStock &&
            hasDefaultItemForModifierCategory(firstItemOption)) {
            return firstItemOption;
        }

        return null;
    }

    _assignDefaultSetMealItem(setMealCategories, cartItem) {
        // Assign default SetMealItem
        for (const setMealCategory of setMealCategories) {
            const setMealItem = this._getDefaultSetMealItem(setMealCategory);

            if (!setMealItem) continue;

            cartItem.setMealItems.push(this.buildCartSetMealItem(setMealCategory, setMealItem, setMealCategory.minQty));
        }
    }

    _assignDefaultModifierItem(modifierCategories, cartItem = null, cartSetMealItem = null) {
        // Assign default ModifierItem for CartItem or CartSetMealItem
        for (const modifierCategory of modifierCategories) {
            const modifierItem = modifierCategory.items.find(x => x.isDefault && !x.isOutOfStock);

            if (!modifierItem) continue;

            if (cartSetMealItem === null) {
                const cartItemModifierItem = this.buildCartItemModifierItem(modifierCategory, modifierItem);
                cartItem.modifierItems.push(cartItemModifierItem);
            } else {
                const cartSetMealItemModifierItem = this.buildCartSetMealItemModifierItem(cartSetMealItem, modifierCategory, modifierItem);
                cartSetMealItem.modifierItems.push(cartSetMealItemModifierItem);
            }
        }
    }
}