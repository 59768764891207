import Home from 'pages/Home';
import Menu from 'pages/Menu';
import Redirect from 'pages/Redirect';

export const pageNames = {
    Home: 'Home',
    Menu: 'Menu',
    Redirect: 'Redirect'
};

export const pagePaths = {
    Home: '/home',
    Menu: '/menu',
    Redirect: '/redirect/:eventType',
    QRCodeInvalid: '/qrCodeInvalid.html'
};

export const pageRoutes = [
    {
        name: pageNames.Home,
        path: pagePaths.Home,
        component: Home
    },
    {
        name: pageNames.Menu,
        path: pagePaths.Menu,
        component: Menu
    },
    {
        name: pageNames.Redirect,
        path: pagePaths.Redirect,
        component: Redirect
    }
];