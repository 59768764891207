import Checkbox from 'components/Checkbox';
import Radio from 'components/Radio';
import RippleWrapper from 'components/RippleWrapper';
import MenuItemPrice from 'components.special/MenuItemPrice';
import * as ServiceTypeConstants from 'data/serviceTypeConstant';
import withOrderService from 'hocs/withOrderService';
import * as ComponentUtils from 'utils/component';

const TakeAwayPanel = ({ cart, orderSessionData, data, menuItem, onValueChange }) => {
    const { orderServiceType } = orderSessionData;
    const { menuItemGuid, serviceType } = data;
    const { takeAwayItem } = menuItem;

    const handleServiceTypeChange = (checked) => {
        onValueChange({
            serviceType: checked ? ServiceTypeConstants.TakeAway : ServiceTypeConstants.DineIn,
            takeAwayItem: checked && takeAwayItem ? cart.buildCartItemTakeAwayItem(takeAwayItem) : null
        });
    };

    return (
        <RippleWrapper
            className="apps-ui-menu-item-component-customize-panel d-flex align-items-center p-3"
            htmlFor={data.menuItemGuid}
        >
            <div className="flex-grow-1 d-flex flex-column">
                <span className="fw-bold">Takeaway</span>

                {takeAwayItem &&
                    <span className="text-muted fs-6">{takeAwayItem.name}</span>
                }

                <MenuItemPrice
                    as="span"
                    className="text-muted fs-5"
                    value={takeAwayItem ? takeAwayItem.price : 0}
                    prefix="+"
                    prefixSmallFont
                    isCurrencyFormat
                />
            </div>

            <span className="col-1 d-inline-flex align-items-center justify-content-center">
                {orderServiceType === ServiceTypeConstants.DineIn
                    ? <Checkbox
                        value={menuItemGuid}
                        checked={serviceType === ServiceTypeConstants.TakeAway}
                        handleChange={handleServiceTypeChange}
                    />
                    : <Radio
                        value={menuItemGuid}
                        groupValue="take-away-list"
                        checked={serviceType === ServiceTypeConstants.TakeAway}
                        handleChange={handleServiceTypeChange}
                    />}
            </span>
        </RippleWrapper>
    );
};

const hocs = [
    withOrderService
];

export default ComponentUtils.compose(hocs)(TakeAwayPanel);