import { useRef } from 'react';

const RippleWrapper = ({ className, children, ...rest }) => {
    const wrapperRef = useRef(null);

    function showRipple(e) {
        // Source : https://natclark.com/tutorials/javascript-ripple-effect/
        // Step 1 : Create a ripple element
        const wrapperEl = wrapperRef.current;
        const rippleEl = document.createElement('div');

        // Step 2 : Get some position values from wrapper element and derived the maximum size to be used by ripple element
        const { width, height, left, top } = wrapperEl.getBoundingClientRect();
        const size = Math.max(width, height);

        // Step 3 : Adjust the position of the ripple element 
        rippleEl.style = `
            width: ${size}px;
            height: ${size}px;
            left: ${e.clientX - left - size / 2}px !important;
            top: ${e.clientY - top - size / 2}px !important;
        `.trim();

        // Step 4 : Add ripple className to ripple element and append it to the wrapper element
        wrapperEl.appendChild(rippleEl);
        rippleEl.classList.add('ripple');

        // Step 5 : Remove ripple element after 0.5s
        setTimeout(() => wrapperEl.removeChild(rippleEl), 500);
    }

    return (
        <label
            className={`apps-component-ripple-wrapper ${className}`}
            onClick={showRipple}
            ref={wrapperRef}
            {...rest}
        >
            {children}
        </label>
    );
}

export default RippleWrapper;