/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import BackButton from 'components/BackButton';
import * as ZIndexUtils from 'utils/zIndexUtils';

const Dialog = ({ isOpen, title, footer, fullScreen, centered, onOpened, closeDialog, children, ...rest }) => {
    const modalRef = useRef(null);

    const modalCss = css`
        .modal-dialog {
            max-width: var(--max-width);
        }
    `;

    const modalFullScreenCss = css`
        .modal-dialog {
            min-height: 100%;
            margin: auto;
        }
        
        .modal-dialog > .modal-content {
            min-height: 100%;
        }
    `;

    const modalCenteredCss = css`
        @media (max-width: 480px) {
            .modal-dialog {
                margin-left: 1rem;
                margin-right: 1rem;
            }
        }

        @media (min-width: 481px) {
            .modal-dialog {
                margin: 1.75em auto;
            }
        }
    `;

    const modalCssList = [modalCss];
    if (fullScreen) modalCssList.push(modalFullScreenCss);
    if (centered) modalCssList.push(modalCenteredCss);

    function onDialogOpened() {
        ZIndexUtils.updateZIndexWithClassName('.modal-backdrop');
        ZIndexUtils.updateZIndexWithComponent(modalRef.current.dialog);

        if (onOpened) {
            onOpened();
        }
    }

    return (
        <Modal
            dialogClassName="modal-dialog-scrollable"
            css={modalCssList}
            show={isOpen}
            enforceFocus={false}
            centered={centered}
            onHide={closeDialog}
            onShow={onDialogOpened}
            ref={modalRef}
            {...rest}
        >
            {fullScreen && title &&
                <div
                    className="d-flex align-items-center justify-content-center position-sticky top-0 bg-white"
                    style={{
                        minHeight: '50px',
                        boxShadow: 'var(--box-shadow)',
                        zIndex: 2
                    }}
                >
                    <BackButton className="position-absolute ion-ios-arrow-back start-0 fs-1 ps-3" onClick={closeDialog} />
                    <span className="fw-bold">{title}</span>
                </div>
            }

            {centered &&
                <BackButton
                    className="d-flex align-items-center justify-content-center text-primary bg-white rounded-circle position-absolute ion ion-md-close fs-1"
                    style={{
                        width: '40px',
                        height: '40px',
                        padding: '10px',
                        top: '10px',
                        right: '10px',
                        zIndex: 2
                    }}
                    onClick={closeDialog}
                />
            }

            <Modal.Body className="p-0">
                {children}
            </Modal.Body>

            {footer && footer}
        </Modal>
    );
}

export default Dialog;