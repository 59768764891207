import React from 'react';
import { useOutletContext } from 'react-router-dom';

// Note : This HOC should be used on components that are children or descendants of React Router <Outlet /> component
function withOutlet(WrappedComponent) {
    function WithOutlet(props) {
        const { setAllowNavigation } = useOutletContext();

        return (
            <WrappedComponent
                setAllowNavigation={setAllowNavigation}
                {...props}
            />
        );
    }

    return WithOutlet;
}

export default withOutlet;