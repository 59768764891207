import { pageRoutes } from './routes';

export function getPageRoute(name) {
    return pageRoutes.find(x => x.name === name);
}

export function getPagePath(name, options) {
    const route = getPageRoute(name);
    let path = route ? route.path : '';

    if (options && path) {
        let queryString = '';

        for (const prop in options) {
            if (options[prop] !== null && options[prop] !== undefined) {
                if (path.indexOf(`:${prop}`) !== -1) {
                    path = path.replace(`:${prop}`, encodeURIComponent(options[prop]));
                } else {
                    queryString += `${queryString ? '&' : '?'}${prop}=${encodeURIComponent(options[prop])}`;
                }
            }
        }

        path += queryString;
    }

    return path;
}

export function navigateToPage(navigate, name, options) {
    const path = getPagePath(name);
    navigate(path, { state: options });
}

export function redirectToPageByName(name, options) {
    const path = getPagePath(name, options);
    redirectToPageByPath(path);
}

export function redirectToPageByPath(path) {
    window.location.href = path;
}