import React, { useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import AccordionButton from 'components/AccordionButton';
import Badge from 'components/Badge';
import Button from 'components/Button';
import CheckBox from 'components/Checkbox';
import Icon from 'components/Icon';
import Image from 'components/Image';
import Radio from 'components/Radio';
import RippleWrapper from 'components/RippleWrapper';
import MenuItemPrice from 'components.special/MenuItemPrice';
import MenuItemQuantityPanel from 'components.special/MenuItemQuantityPanel';
import withOrderService from 'hocs/withOrderService';
import * as ComponentUtils from 'utils/component';
import { usePrevious } from 'utils/component';
import * as EventUtils from 'utils/eventUtils';
import * as Toast from 'utils/toast';
import ValidatorElement from './Shared/ValidatorElement';
import * as CustomizeBuilder from '../utils/customizeBuilder';

const withAnimationClass = 'with-animation';

const SetMealCategoryPanel = ({
    setMealCategory,
    cartSetMealItems,
    activeEventKey,
    toggleAccordion,
    openImageDialog,
    handleSetMealItemChange,
    addSetMealItem,
    updateSetMealItem,
    accordionButtonRef,
    cart }) => {

    const { menuSetMealCategoryGuid, name, minQty, maxQty } = setMealCategory;
    const isRadioInput = minQty && maxQty && minQty === 1 && maxQty === 1;
    const allowedQtyText = CustomizeBuilder.getAllowedQtyText(minQty, maxQty);

    const cartSetMealCategorySetMealItems = cartSetMealItems.filter(x => x.menuSetMealCategoryGuid === menuSetMealCategoryGuid);
    const totalQty = cartSetMealCategorySetMealItems.reduce((total, item) => total + item.quantity, 0);

    const isAccordionExpanded = activeEventKey !== null;

    const [validationDtl, setValidationDtl] = useState(null);
    const prevCartSetMealCategorySetMealItems = usePrevious(cartSetMealCategorySetMealItems);

    useEffect(() => {
        const isChangesMadeByOthers =
            prevCartSetMealCategorySetMealItems !== undefined &&
            prevCartSetMealCategorySetMealItems.length === cartSetMealCategorySetMealItems.length &&
            prevCartSetMealCategorySetMealItems.every(x => cartSetMealCategorySetMealItems.some(y => x.menuSetMealItemGuid === y.menuSetMealItemGuid && x.quantity === y.quantity));

        if (isChangesMadeByOthers) return;

        // Update badge
        const currentValidationDtl = CustomizeBuilder.getValidationDtl(minQty, totalQty);
        if (validationDtl === null) {
            setValidationDtl(currentValidationDtl);
        } else if (validationDtl.badgeText !== currentValidationDtl.badgeText) {
            currentValidationDtl.badgeClass += ` ${withAnimationClass}`;
            setValidationDtl(currentValidationDtl);
        }

        if (!activeEventKey) return;

        // Collapse accordion if selected up to maxQty
        const isReachedMaxQty = maxQty && maxQty === totalQty;
        if (isReachedMaxQty) {
            toggleAccordion(menuSetMealCategoryGuid);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cartSetMealItems]);

    function onSetMealItemSelected(setMealItem, resetSetMealCategory) {
        if (setMealItem.modifierCategories.length === 0) {
            // Modifier customization not needed
            handleSetMealItemChange({
                setMealItems: [
                    ...cartSetMealItems.filter(x => resetSetMealCategory ? x.menuSetMealCategoryGuid !== menuSetMealCategoryGuid : true),
                    cart.buildCartSetMealItem(setMealCategory, setMealItem)
                ]
            });
        } else {
            // Modifier customization needed
            const maxQtyToAdd = maxQty === null ? null : maxQty - totalQty;
            addSetMealItem(setMealCategory, setMealItem, maxQtyToAdd, resetSetMealCategory);
        }
    }

    function handleRadioInputChange(setMealItem) {
        onSetMealItemSelected(setMealItem, true);
    }

    function handleCheckboxInputChange(checked, setMealItem) {
        if (checked) {
            if (maxQty && maxQty === totalQty && maxQty > 1) {
                Toast.showErrorMessage(`You are not allowed to select more than ${maxQty} for ${name}.`);
                return;
            }

            onSetMealItemSelected(setMealItem, maxQty && maxQty === 1);
        } else {
            handleSetMealItemChange({
                setMealItems: [...cartSetMealItems.filter(x => x.menuSetMealItemGuid !== setMealItem.menuSetMealItemGuid)]
            });
        }
    }

    function handleUpdateSetMealItem(e, setMealItem, cartSetMealItem) {
        EventUtils.cancelEventPropagation(e);

        const maxQtyToAdd = maxQty === null ? null : maxQty - totalQty + cartSetMealItem.quantity;
        updateSetMealItem(setMealItem, cartSetMealItem, maxQtyToAdd);
    }

    function handleOpenImageDialog(e, image) {
        EventUtils.cancelEventPropagation(e);
        openImageDialog(image);
    }

    function addSetMealItemQuantity(e, setMealItem, cartSetMealItem) {
        EventUtils.cancelEventPropagation(e);

        if (maxQty !== null && maxQty === totalQty) {
            Toast.showErrorMessage(`You are not allowed to select more than ${maxQty} for ${name}.`);
            return;
        }

        cartSetMealItem.quantity += 1;
        handleSetMealItemChange({
            setMealItems: [
                ...cartSetMealItems.filter(x => x.menuSetMealItemGuid !== setMealItem.menuSetMealItemGuid),
                { ...cartSetMealItem }
            ]
        });
    }

    function minusSetMealItemQuantity(e, cartSetMealItem) {
        EventUtils.cancelEventPropagation(e);

        if (cartSetMealItem.quantity === 1) {
            handleSetMealItemChange({
                setMealItems: [...cartSetMealItems.filter(x => x.menuSetMealItemGuid !== cartSetMealItem.menuSetMealItemGuid)]
            });
        } else {
            cartSetMealItem.quantity -= 1;
            handleSetMealItemChange({
                setMealItems: [
                    ...cartSetMealItems.filter(x => x.menuSetMealItemGuid !== cartSetMealItem.menuSetMealItemGuid),
                    { ...cartSetMealItem }
                ]
            });
        }
    }

    function renderSetMealItem(setMealItem) {
        const value = setMealItem.menuSetMealItemGuid;
        const groupValue = menuSetMealCategoryGuid;
        const disabled = setMealItem.isOutOfStock;
        const cartSetMealItem = cartSetMealCategorySetMealItems.find(x => x.menuSetMealItemGuid === setMealItem.menuSetMealItemGuid);
        const isSelected = !!cartSetMealItem;

        const inputContent = isRadioInput
            ? <Radio
                value={value}
                groupValue={groupValue}
                checked={isSelected}
                disabled={disabled}
                handleChange={() => handleRadioInputChange(setMealItem)}
            />
            : <CheckBox
                value={value}
                checked={isSelected}
                disabled={disabled}
                handleChange={checked => handleCheckboxInputChange(checked, setMealItem)}
            />;

        return (
            <RippleWrapper
                key={value}
                className="d-flex mt-3"
                style={{ opacity: disabled ? 0.5 : undefined }}
                htmlFor={value}
            >
                {setMealItem.image &&
                    <Image
                        className="me-3"
                        style={{ borderRadius: '6px' }}
                        width='65px'
                        height='65px'
                        imageSrc={setMealItem.image}
                        onClick={e => handleOpenImageDialog(e, setMealItem.image)}
                    />
                }

                <div className="flex-grow-1 d-flex flex-column fs-5">
                    <span>{setMealItem.name}</span>

                    <MenuItemPrice
                        as="span"
                        className="text-muted"
                        value={setMealItem.price}
                        prefix="+"
                        prefixSmallFont
                        isCurrencyFormat
                    />

                    {isSelected &&
                        <>
                            {cartSetMealItem.modifierItems.length > 0 &&
                                <div className="d-flex flex-wrap gap-2 mt-2">
                                    {cartSetMealItem.modifierItems.map(x =>
                                        <Badge key={x.cartSetMealItemModifierItemId}>
                                            {x.description}
                                        </Badge>
                                    )}
                                </div>
                            }

                            {(!isRadioInput || setMealItem.modifierCategories.length > 0) &&
                                <div className="d-flex fs-6 align-items-center mt-3">
                                    {!isRadioInput &&
                                        <MenuItemQuantityPanel
                                            className="me-2"
                                            height="30px"
                                            width="30px"
                                            value={cartSetMealItem.quantity}
                                            handleAdd={e => addSetMealItemQuantity(e, setMealItem, cartSetMealItem)}
                                            handleMinus={e => minusSetMealItemQuantity(e, cartSetMealItem)}
                                        />
                                    }

                                    {setMealItem.modifierCategories.length > 0 &&
                                        <Button
                                            className="fs-6 ms-2"
                                            primary
                                            outline
                                            onClick={e => handleUpdateSetMealItem(e, setMealItem, cartSetMealItem)}
                                        >
                                            <div className="d-flex align-items-center">
                                                <Icon name="fas fa-pencil-alt" label="Edit" />
                                            </div>
                                        </Button>
                                    }
                                </div>
                            }
                        </>
                    }

                    {disabled &&
                        <span className="fw-bold mt-2" style={{ color: 'var(--primary-dark)' }}>
                            Not Available
                        </span>
                    }
                </div>

                <span className="col-1 align-self-center text-center ms-3">
                    {inputContent}
                </span>
            </RippleWrapper>
        );
    }

    function renderSelectedSetMealItem(cartSetMealCategorySetMealItem) {
        return (
            <div key={cartSetMealCategorySetMealItem.cartSetMealItemId}>
                <span className="d-flex text-muted fs-5 mt-2">
                    <span className="flex-grow-1">{`${cartSetMealCategorySetMealItem.name} x${cartSetMealCategorySetMealItem.quantity}`}</span>

                    <MenuItemPrice
                        as="span"
                        className="col-3 ms-1 text-end"
                        value={cartSetMealCategorySetMealItem.price}
                        prefix="+"
                        prefixSmallFont
                        isCurrencyFormat
                    />
                </span>

                {cartSetMealCategorySetMealItem.modifierItems.length > 0 &&
                    <span className="d-flex flex-wrap gap-2 mt-2">
                        {cartSetMealCategorySetMealItem.modifierItems.map(x =>
                            <Badge key={x.cartSetMealItemModifierItemId}>
                                {x.description}
                            </Badge>
                        )}
                    </span>
                }
            </div>
        );
    }

    return (
        <div
            id={menuSetMealCategoryGuid}
            className="apps-ui-menu-item-component-customize-panel d-flex flex-column p-3"
        >
            {/* Accordion Header */}
            <div className="d-flex flex-column" onClick={() => toggleAccordion(menuSetMealCategoryGuid)}>
                <div className="d-flex align-items-baseline">
                    <span className="flex-grow-1 fw-bold">
                        {name}
                    </span>

                    {validationDtl
                        ? <span className={`col-3 text-white ms-auto ${validationDtl.badgeClass}`}>
                            {validationDtl.badgeText}
                        </span>
                        : <span className="col-3 ms-auto" />
                    }

                    <AccordionButton
                        primary
                        className="col-1 ms-1"
                        eventKey={menuSetMealCategoryGuid.toString()}
                        activeEventKey={activeEventKey}
                        ref={el => accordionButtonRef.current = el}
                    />
                </div>

                {isAccordionExpanded
                    ? allowedQtyText && <span className="text-muted fs-6" style={{ fontWeight: 600 }}>{allowedQtyText}</span>
                    : cartSetMealCategorySetMealItems.map(renderSelectedSetMealItem)
                }
            </div>

            {/* Accordion Body */}
            <Accordion.Collapse eventKey={menuSetMealCategoryGuid.toString()}>
                <>
                    {setMealCategory.items.map(renderSetMealItem)}

                    {validationDtl && validationDtl.showValidator &&
                        <ValidatorElement message={`Please select at least ${minQty} item(s).`} />
                    }
                </>
            </Accordion.Collapse>
        </div>
    );
};

const hocs = [
    withOrderService
];

export default React.memo(ComponentUtils.compose(hocs)(SetMealCategoryPanel));