import React, { useRef, useImperativeHandle } from 'react';

const TabContent = React.forwardRef((props, ref) => {
    const {
        tabPrefix = 'tab',
        defaultTabKey,
        sticky,
        boxShadow,
        top,
        height,
        onTabChanged,
        children
    } = props;

    const tabRefs = useRef({});
    const tabPaneRefs = useRef({});

    useImperativeHandle(ref, () => ({
        show: (tabKey) => onTabSelected(tabKey)
    }));

    const createTabKey = (index) => `${tabPrefix}${index}`;

    const isActiveTab = (index) => {
        const tabKey = createTabKey(index);

        if (defaultTabKey) {
            return defaultTabKey === tabKey;
        }
        else {
            return index === 0;
        }
    };

    const onTabSelected = (tabKey) => {
        const tabEl = tabRefs.current[tabKey];
        const tabPaneEl = tabPaneRefs.current[tabKey];

        if (!tabEl || !tabPaneEl) return;

        // Note : Bootstrap's fade class uses a transition duration of 0.15s (150ms)
        const doDelayedTask = (action) => setTimeout(action, 150);

        // Tabs
        Object.values(tabRefs.current).forEach(x => {
            if (x !== tabEl) {
                x.classList.remove('active');
            }
        });

        tabEl.classList.add('active');

        // Tab Panes
        Object.values(tabPaneRefs.current).forEach(x => {
            if (x !== tabPaneEl) {
                x.classList.remove('show');
                doDelayedTask(() => x.classList.remove('active'));
            }
        });

        tabPaneEl.classList.add('active');
        doDelayedTask(() => tabPaneEl.classList.add('show'));

        if (onTabChanged) {
            doDelayedTask(() => onTabChanged(tabKey));
        }
    };

    const renderTab = (tabPage, index) => {
        const tabKey = createTabKey(index);
        const isActive = isActiveTab(index);

        return (
            <li
                key={index}
                className="nav-item"
            >
                <button
                    className={`nav-link h-100 ${isActive ? 'active' : ''}`}
                    type="button"
                    onClick={() => onTabSelected(tabKey)}
                    ref={el => tabRefs.current[tabKey] = el}
                >
                    {tabPage.props.title}
                </button>
            </li>
        );
    };

    const renderTabPane = (tabPage, index) => {
        const tabKey = createTabKey(index);
        const isActive = isActiveTab(index);

        return (
            <div
                key={index}
                className={`tab-pane fade ${isActive ? 'show active' : ''}`}
                ref={el => tabPaneRefs.current[tabKey] = el}
            >
                {tabPage.props.children}
            </div>
        );
    };

    const navTabStyle = {
        top,
        height,
        zIndex: 1,
        background: 'white',
        position: sticky ? 'sticky' : undefined,
        boxShadow: boxShadow ? 'var(--box-shadow)' : undefined
    };

    const tabPages = children ? children.filter(item => item) : [];

    return (
        <>
            <ul className="nav nav-tabs nav-justified" style={navTabStyle}>
                {tabPages.map(renderTab)}
            </ul>

            <div className="tab-content">
                {tabPages.map(renderTabPane)}
            </div>
        </>
    );
});

export default TabContent;