import EmptyLine from 'components/EmptyLine';
import MenuItemImage from 'components.special/MenuItemImage';
import MenuItemName from 'components.special/MenuItemName';
import MenuItemQuantityIcon from 'components.special/MenuItemQuantityIcon';
import withOrderService from 'hocs/withOrderService';
import * as ComponentUtils from 'utils/component';
import MenuItemPriceTag from './MenuItemPriceTag';

const MenuItem = ({ cart, menuItem, isLastMenuItem, viewMenuItem }) => {
    const { menuItemGuid, name, description, image, displayCode, isOutOfStock, isTrending } = menuItem;

    const qty = cart.getTotalQuantityForMenuItem(menuItemGuid);

    return (
        <>
            <div
                className="d-flex py-3"
                style={{ opacity: isOutOfStock ? 0.5 : undefined }}
                onClick={() => !isOutOfStock && viewMenuItem(menuItemGuid)}
            >
                <div className="position-relative">
                    <MenuItemImage
                        className="border"
                        style={{ borderRadius: '6px' }}
                        imageSrc={image}
                        name={name}
                        width='80px'
                        height='80px'
                        isLazyLoading
                    />

                    {qty > 0 &&
                        <MenuItemQuantityIcon
                            className="position-absolute"
                            style={{ top: '-10px', left: '-5px' }}
                            border
                            shadow
                        >
                            {qty}
                        </MenuItemQuantityIcon>
                    }
                </div>

                <div className="d-flex flex-column flex-grow-1 ps-3">
                    {(isTrending || isOutOfStock) &&
                        <span className="d-flex align-items-center fs-6 mb-2">
                            {isTrending && <span className="text-primary me-3">Most Ordered</span>}
                            {isOutOfStock && <span className="text-danger">Unavailable</span>}
                        </span>
                    }

                    <span className="fs-5">
                        <MenuItemName name={name} displayCode={displayCode} />
                    </span>

                    <span className="text-muted fs-6 mt-1">{description}</span>

                    <MenuItemPriceTag menuItem={menuItem} />
                </div>
            </div>

            {!isLastMenuItem && <EmptyLine />}
        </>
    );
}

const hocs = [
    withOrderService
];

export default ComponentUtils.compose(hocs)(MenuItem);