import { Component } from 'react';
import BlockUIContext from 'contexts/BlockUIContext';
import { getDisplayName } from 'utils/component';

export default function withBlockUI(WrappedComponent) {
    class WithBlockUI extends Component {
        render() {
            const { blockUI, unblockUI } = this.context;

            return (
                <WrappedComponent
                    blockUI={blockUI}
                    unblockUI={unblockUI}
                    {...this.props}
                />
            );
        }
    }

    WithBlockUI.displayName = `WithBlockUI(${getDisplayName(WrappedComponent)})`;
    WithBlockUI.contextType = BlockUIContext;

    return WithBlockUI;
}