import Overlay from 'components/Overlay';
import * as EventUtils from 'utils/eventUtils';

const FinalTotalIcon = () => {
    return (
        <Overlay
            trigger="click"
            placement="top"
            popoverText="The final total is an estimate and may differ slightly when you pay at the counter."
        >
            <div
                className="col-1 icon-icon-info text-muted"
                onClick={EventUtils.cancelEventPropagation}
            />
        </Overlay>
    );
};

export default FinalTotalIcon;