import React from 'react';
import MenuItemPrice from 'components.special/MenuItemPrice';
import MenuItemQuantityIcon from 'components.special/MenuItemQuantityIcon';
import withOrderService from 'hocs/withOrderService';
import withOrderFunction from 'ui/Order/hocs/withOrder';
import * as ComponentUtils from 'utils/component';

const Footer = ({ cart, viewCart }) => {
    return (
        <div
            className="fixed-bottom mb-3"
            style={{
                margin: 'auto',
                height: '40px',
                maxWidth: 'var(--max-width)'
            }}
        >
            <div
                className="apps-general-shadow-pulse d-flex align-items-center bg-primary h-100 mx-3"
                style={{ borderRadius: '6px', cursor: 'pointer' }}
                onClick={viewCart}
            >
                <div className="col-2 ps-3 py-1">
                    <MenuItemQuantityIcon>
                        {cart.totalQuantity}
                    </MenuItemQuantityIcon>
                </div>

                <div className="col-6 ps-5 text-white text-center">
                    View Your Cart
                </div>

                <div className="col-4 pe-3 text-end">
                    <MenuItemPrice
                        as="span"
                        className="text-white fw-bold"
                        value={cart.finalTotal}
                        isCurrencyFormat
                    />
                </div>
            </div>
        </div>
    );
}

const hocs = [
    withOrderService,
    withOrderFunction
];

export default React.memo(ComponentUtils.compose(hocs)(Footer));