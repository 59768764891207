import classNames from 'classnames';

const HintText = ({ as: Element = 'span', className, style, children }) => {
    const classes = classNames('text-muted fs-6', className);

    return (
        <Element className={classes} style={style}>
            {children}
        </Element>
    );
};

export default HintText;