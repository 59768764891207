/** @jsxImportSource @emotion/react */
/* eslint-disable react/no-unknown-property */
import { css } from '@emotion/react';

const BlockUI = ({ blocking, message }) => {
    const getSpinContainerStyle = () => {
        const spinContainerStyle = {
            position: 'absolute',
            top: '50%',
            right: 0,
            textAlign: 'center',
            zIndex: 10001
        };

        if (message) {
            spinContainerStyle.left = '50%';
            spinContainerStyle.transform = 'translate(-50%, -50%)';
            spinContainerStyle.width = '250px';
            spinContainerStyle.padding = '10px';
            spinContainerStyle.border = '1px solid #ccc';
            spinContainerStyle.borderRadius = '6px';
            spinContainerStyle.boxShadow = '0 6px 12px rgba(0, 0, 0, .25)';
            spinContainerStyle.backgroundColor = 'white';
        } else {
            spinContainerStyle.left = 0;
            spinContainerStyle.transform = 'translateY(-50%)';
        }

        return spinContainerStyle;
    }

    const getSpinStyle = () => {
        const spinStyle = {
            width: 40,
            height: 40,
            position: 'relative'
        };

        if (message) {
            spinStyle.margin = 'auto';
        } else {
            spinStyle.margin = '100px auto';
        }

        return spinStyle;
    }

    const containerCss = css`
        position: fixed;
        z-index: 10000;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        cursor: wait;
        overflow: hidden;
        animation-name: ${blocking ? 'fadeIn' : 'fadeOut'};
        animation-duration: 0.5s;
        height: ${blocking ? '100%' : '0%'};
        opacity: ${blocking ? 1 : 0};
        
        @keyframes fadeIn {
            0% {opacity: 0; }
            100% {opacity: 1; }
        }
        
        @keyframes fadeOut {
            0% {opacity: 1; height: 100%; } 
            99.9% {opacity: 0; height: 100%; }
            100% {opacity: 0; height: 0; }
        }
    `;

    const overlayStyle = {
        width: '100%',
        height: '100%',
        opacity: 0.25
    };

    const spinChildCss = css`
        width: 35px;
        aspect-ratio: 1;
        border-radius: 50%;
        border: 8px solid;
        border-color: currentColor #0000;
        animation: l1 1s infinite;

        @keyframes l1 {
            to {
                transform: rotate(.5turn)
            }
        }
     `;

    return (
        <div css={containerCss} >
            <div className={`apps-component-block-ui-overlay${blocking ? ' blocking' : ''}`} style={overlayStyle} />
            <div style={getSpinContainerStyle()}>
                <div style={getSpinStyle()}>
                    <div className="text-primary" css={spinChildCss}></div>
                </div>
                {message && <div className="mt-1">{message}</div>}
            </div>
        </div>
    );
};

export default BlockUI;