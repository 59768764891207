import Overlay from 'components/Overlay';
import MenuItemPrice from 'components.special/MenuItemPrice';
import { getMenuItemTag } from 'data/menuItemTagText';
import * as EventUtils from 'utils/eventUtils';

const MenuItemPriceTag = ({ menuItem }) => {
    const { setMeal, price, tags } = menuItem;

    const effectivePrice = (setMeal ? setMeal.priceFrom : price) || 0;
    const isSetMeal = !!setMeal;

    return (
        <div className="d-flex align-items-center flex-wrap gap-2 mt-2">
            <MenuItemPrice
                as="span"
                className="fs-5"
                value={effectivePrice}
                suffix={`${isSetMeal ? '++' : ''}`}
                prefixSmallFont
                suffixSmallFont
                isCurrencyFormat={false}
            />

            {tags &&
                tags.split(', ').map((tag, index) => {
                    const menuItemTag = getMenuItemTag(tag);
                    const menuItemTagText = menuItemTag ? menuItemTag.text : '';
                    const menuItemTagColor = menuItemTag ? menuItemTag.color : '';

                    return (
                        <Overlay
                            key={index}
                            trigger="click"
                            placement="top"
                            autoCloseTime={1000}
                            popoverText={tag}
                        >
                            <span
                                className={`${menuItemTagText} fs-6 p-1`}
                                style={{
                                    color: menuItemTagColor,
                                    background: 'rgba(239, 239, 239, 0.4)',
                                    border: '1px solid #e6e6e6',
                                    borderRadius: '6px'
                                }}
                                onClick={EventUtils.cancelEventPropagation}
                            />
                        </Overlay>
                    );
                })
            }
        </div>
    );
};

export default MenuItemPriceTag;