import React, { useRef, useCallback } from 'react';
import { Outlet, useBlocker } from 'react-router-dom';
import * as DocumentIdConstants from 'data/documentIdConstant';
import withRouter from 'hocs/withRouter';
import withQuitOrder from 'ui/QuitOrder/hocs/withQuitOrder';
import * as ComponentUtils from 'utils/component';
import { pagePaths } from 'utils/routes';
import ErrorBoundary from './ErrorBoundary';

const Router = ({ openQuitOrderDialog }) => {
    const allowNavigationRef = useRef(false);

    const setAllowNavigation = useCallback((allowNavigation) => {
        allowNavigationRef.current = allowNavigation;
    }, []);

    const closeElementByBackButton = useCallback((element) => {
        const backButton = element.querySelector(`#${DocumentIdConstants.ComponentBackButton}`);
        if (backButton !== null) backButton.click();
    }, []);

    const shouldBlock = useCallback(({ currentLocation, _ }) => {
        // No checking is required if navigation is explicitly allowed
        if (allowNavigationRef.current) {
            setAllowNavigation(false);
            return false;
        }

        // Check for open MessageBox dialogs
        if (document.querySelectorAll('.dx-dialog').length > 0) {
            return true;
        }

        // Check for open dialogs
        const dialogs = document.querySelectorAll('.modal-dialog');
        if (dialogs.length > 0) {
            closeElementByBackButton(dialogs[dialogs.length - 1]);
            return true;
        }

        // Check for open sidenav
        const sidenav = document.querySelector('.offcanvas-header');
        if (sidenav !== null) {
            closeElementByBackButton(sidenav);
            return true;
        }

        // Check for back navigation from Menu page
        if (currentLocation.pathname === pagePaths.Menu) {
            openQuitOrderDialog(setAllowNavigation);
            return true;
        }

        return false;
    }, [setAllowNavigation, closeElementByBackButton, openQuitOrderDialog]);

    useBlocker(shouldBlock);

    return (
        <ErrorBoundary>
            <Outlet context={{ setAllowNavigation }} />
        </ErrorBoundary>
    );
};

const hocs = [
    withRouter,
    withQuitOrder
];

export default ComponentUtils.compose(hocs)(Router);