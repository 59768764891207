import { useState, useRef } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import AccordionButton from 'components/AccordionButton';
import * as OrderStatusConstants from 'data/orderStatusConstant';
import withOrderService from 'hocs/withOrderService';
import withSettings from 'hocs/withSettings';
import * as ComponentUtils from 'utils/component';
import * as NumberUtils from 'utils/numberUtils';
import FinalTotalIcon from '../Shared/FinalTotalIcon';
import Summary, { SummaryType } from '../Shared/Summary';

const Footer = ({ cart, orderSessionData, settings, orders }) => {
    const { useEPayment } = orderSessionData;
    const { localCurrencySymbol, currencyFormat, allowPayLaterAtCounter } = settings;

    const [activeEventKey, setActiveEventKey] = useState(null);

    const accordionButtonRef = useRef(null);
    const accordionHeaderRef = useRef(null);

    const toggleAccordion = () => {
        accordionButtonRef.current.click();

        if (activeEventKey) {
            accordionHeaderRef.current.style.removeProperty('border-bottom');
        } else {
            accordionHeaderRef.current.style.borderBottom = '1px solid var(--grey-300)';
        }
    }

    const round = (value) => NumberUtils.roundNumberFromFormat(value, currencyFormat);

    let totalQuantity = 0, totalAmountExTax = 0, tax = 0, serviceCharge = 0, roundingAdjustment = 0, finalTotal = 0;

    for (const order of orders) {
        // Rejected, cancelled and paid orders are excluded
        if (order.status === OrderStatusConstants.WaitingToAccept ||
            order.status === OrderStatusConstants.PendingPayment ||
            order.status === OrderStatusConstants.Accepted) {
            totalQuantity += order.totalQuantity;
            totalAmountExTax += order.totalAmountExTax;
            tax += order.tax;
            serviceCharge += order.serviceCharge;
        }
    }

    const netTotal = round(totalAmountExTax + tax + serviceCharge);
    finalTotal = round(cart.calculateFinalTotal(netTotal));
    roundingAdjustment = round(finalTotal - netTotal);

    const summaryData = {
        totalAmountExTax,
        tax,
        serviceCharge,
        roundingAdjustment
    };

    return (
        <div className="d-flex flex-column position-sticky bottom-0">
            <Accordion onSelect={eventKey => setActiveEventKey(eventKey)}>
                {/* Accordion Header */}
                <div
                    className="d-flex align-items-center fw-bold px-3"
                    style={{ height: '50px', borderTop: '1px solid var(--grey-300)' }}
                    onClick={toggleAccordion}
                    ref={accordionHeaderRef}
                >
                    <div className="text-primary">{`${totalQuantity} Items`}</div>

                    <div className="d-flex flex-grow-1 align-items-center justify-content-end">
                        {(!useEPayment || allowPayLaterAtCounter) &&
                            <FinalTotalIcon />
                        }

                        <span className="text-muted fs-6 me-2">FINAL TOTAL:</span>
                        <span className="fs-3">{`${localCurrencySymbol} ${NumberUtils.formatNumber(finalTotal, currencyFormat)}`}</span>
                    </div>

                    <div className="ms-2">
                        <AccordionButton
                            eventKey='0'
                            activeEventKey={activeEventKey}
                            ref={accordionButtonRef}
                        />
                    </div>
                </div>

                {/* Accordion Body */}
                <Accordion.Collapse eventKey="0">
                    <Summary
                        summaryData={summaryData}
                        summaryType={SummaryType.OrderedTabFooter}
                        withWrapper={content =>
                            <div className="d-flex flex-column px-3 pb-3">
                                {content}
                            </div>
                        }
                    />
                </Accordion.Collapse>
            </Accordion>
        </div>
    );
};

const hocs = [
    withOrderService,
    withSettings
];

const FooterWithHocs = ComponentUtils.compose(hocs)(Footer);

export { FooterWithHocs as OrderedTabFooter };