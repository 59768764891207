// DevExtreme
export const DevExtremeUIDialog = 'devExtremeUIDialog';

// Component
export const ComponentBackButton = 'componentBackButton';

// Page
export const PageMenuNavTab = 'pageMenuNavTab';

// UI
export const UIMenuItemEditFormDialog = 'uiMenuItemEditFormDialog';
export const UIMenuItemEditFormForm = 'uiMenuItemEditFormForm';
export const UIMenuItemSetMealItemFormForm = 'uiMenuItemSetMealItemFormForm';
export const UIOrderBillingDetailsDialogForm = 'uiOrderBillingDetailsDialogForm';
export const UIOrderBillingDetailsDialogSaveBillingDetailsInput = 'uiOrderBillingDetailsDialogSaveBillingDetailsInput';