import { useState, useRef } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import AccordionButton from 'components/AccordionButton';
import Button from 'components/Button';
import EmptyLine from 'components/EmptyLine';
import HintText from 'components/HintText';
import Icon from 'components/Icon';
import MenuItemPrice from 'components.special/MenuItemPrice';
import * as OrderStatusConstants from 'data/orderStatusConstant';
import { getOrderStatusItem } from 'data/orderStatusText';
import withSettings from 'hocs/withSettings';
import withUser from 'hocs/withUser';
import * as ComponentUtils from 'utils/component';
import * as DateTimeUtils from 'utils/dateTimeUtils';
import OrderItemPanel from './OrderItemPanel';
import Summary, { SummaryType } from '../Shared/Summary';

const Orders = ({ user, settings, orders, makePayment }) => {
    const { restaurantUseEPayment } = settings;
    const { userId } = user;

    const [activeEventKey, setActiveEventKey] = useState(null);

    const accordionHeaderRefs = useRef({});
    const accordionButtonRefs = useRef({});

    return (
        <Accordion onSelect={eventKey => setActiveEventKey(eventKey)}>
            {orders.map(order => {
                const orderStatusItem = getOrderStatusItem(order.status);

                const orderStatusText = order.status === OrderStatusConstants.Accepted && order.items.some(x => x.quantity !== x.originalQuantity)
                    ? 'Order Partially Accepted'
                    : orderStatusItem.text;

                return (
                    <div
                        key={order.orderId}
                        className="apps-ui-order-component-order-panel"
                        onClick={() => accordionButtonRefs.current[order.orderId].click()}
                        ref={el => accordionHeaderRefs.current[order.orderId] = el}
                    >
                        {/* Accordion Header */}
                        <div
                            className="d-flex align-items-center justify-content-center fs-5 py-2"
                            style={{
                                color: orderStatusItem.color,
                                background: orderStatusItem.background,
                                borderRadius: '12px 12px 0 0',
                                height: '2.5rem'
                            }}
                        >
                            <Icon name={orderStatusItem.icon} label={orderStatusText} />
                        </div>

                        <div className="d-flex">
                            <div className="d-flex flex-column p-3" style={{ borderRight: '2px solid var(--grey-200)', whiteSpace: 'nowrap' }}>
                                <span className="fw-bold mb-1">{order.orderNo}</span>
                                <HintText className="mb-1">{DateTimeUtils.dateToString(DateTimeUtils.stringToDate(order.createdTimeStamp), false)}</HintText>
                                <HintText>{`Created by ${order.userId === userId ? 'you' : 'others'}`}</HintText>
                            </div>

                            <div className="d-flex flex-column flex-grow-1 p-3">
                                <div className="d-flex mb-3">
                                    <div className="d-flex flex-column me-3">
                                        <HintText>Final Total</HintText>
                                        <MenuItemPrice
                                            as="span"
                                            className="fs-5"
                                            value={order.finalTotal}
                                            prefixSmallFont
                                            isCurrencyFormat
                                        />
                                    </div>

                                    <div className="d-flex flex-column me-3">
                                        <HintText>Items</HintText>
                                        <span className="fs-5">{order.totalQuantity}</span>
                                    </div>

                                    <div className="ms-auto">
                                        <AccordionButton
                                            eventKey={order.orderId}
                                            activeEventKey={activeEventKey}
                                            ref={el => accordionButtonRefs.current[order.orderId] = el}
                                        />
                                    </div>
                                </div>

                                {order.status === OrderStatusConstants.PendingPayment &&
                                    <div className="d-flex mt-auto">
                                        {order.hasOngoingPayment
                                            ? <Button
                                                className="fs-5 ms-auto"
                                                style={{
                                                    pointerEvents: 'none',
                                                    background: 'var(--grey-200)',
                                                    border: '1px solid var(--grey-400)'
                                                }}
                                            >
                                                <Icon name="fas fa-hourglass-half fa-spin" label="Payment In Progress" />
                                            </Button>
                                            : <Button
                                                primary
                                                className="fs-5 ms-auto"
                                                onClick={e => makePayment(e, order.orderId)}
                                            >
                                                <Icon name="fas fa-cash-register" label={restaurantUseEPayment ? "Make Payment" : "Pay Later At Counter"} />
                                            </Button>
                                        }
                                    </div>
                                }
                            </div>
                        </div>

                        {/* Accordion Body */}
                        <Accordion.Collapse eventKey={order.orderId} className="pb-2">
                            <>
                                <EmptyLine />

                                <div
                                    className="px-3 mt-3"
                                    style={{ fontWeight: 600 }}
                                >
                                    Order Items
                                </div>

                                {order.items.map(orderItem =>
                                    <OrderItemPanel
                                        key={orderItem.orderItemId}
                                        orderItem={orderItem}
                                    />
                                )}

                                <Summary
                                    summaryData={order}
                                    summaryType={SummaryType.Order}
                                    withWrapper={content =>
                                        <div className="d-flex flex-column px-3 pb-3">
                                            {content}
                                        </div>
                                    }
                                />
                            </>
                        </Accordion.Collapse>
                    </div>
                );
            })}
        </Accordion>
    );
};

const hocs = [
    withSettings,
    withUser
];

export default ComponentUtils.compose(hocs)(Orders);