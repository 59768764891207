import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

function withRouter(WrappedComponent) {
    function WithRouter(props) {
        return (
            <WrappedComponent
                location={useLocation()}
                navigate={useNavigate()}
                params={useParams()}
                {...props}
            />
        );
    }

    return WithRouter;
}

export default withRouter;