// CX-TODO : Refactor to change into a class object, which will accept minQty, maxQty, totalQty as parameters
export function getValidationDtl(minQty, totalQty) {
    let badgeClass = 'apps-ui-menu-item-component badge rounded-pill';
    let badgeText = '';
    let showValidator = false;

    if (minQty === null) {
        badgeClass += ' badge-optional';
        badgeText = 'Optional';
    } else if (minQty <= totalQty) {
        badgeClass += ' badge-completed';
        badgeText = 'Completed';
    } else {
        badgeClass += ' badge-required';
        badgeText = 'Required';
        showValidator = true;
    }

    return {
        badgeClass,
        badgeText,
        showValidator
    };
}

export function getAllowedQtyText(minQty, maxQty) {
    const allowedQtyTexts = [];

    if (minQty !== null) {
        allowedQtyTexts.push(`at least ${minQty}`);
    }

    if (maxQty !== null) {
        allowedQtyTexts.push(`at most ${maxQty}`);
    }

    if (allowedQtyTexts.length > 0) {
        return `Select ${allowedQtyTexts.join(', ')}`;
    }
}