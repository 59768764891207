/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import BackButton from 'components/BackButton';
import Overlay from 'components/Overlay';
import * as DocumentIdConstants from 'data/documentIdConstant';
import * as EventUtils from 'utils/eventUtils';
import * as ZIndexUtils from 'utils/zIndexUtils';

const isHeaderClass = 'is-header';
const closeDialogClass = 'close-dialog';
const restorePositionClass = 'restore-position';

// Note : If custom header is used, then element with 'isHeader' class and BackButton component must be included
const BottomSheetDialog = ({ isOpen, useDefaultHeader = true, title, titleIconText, children, footer, onOpened, closeDialog, ...rest }) => {
    const modalRef = useRef(null);

    const modalCss = css`
        .modal-dialog {      
            position: absolute;
            width: 100%;
            max-width: var(--max-width);  
            margin: auto;
            bottom: 0;
            left: 0;
            right: 0;
        }
        
        .modal-dialog-scrollable {
            height: auto;
        }

        .modal-dialog > .modal-content {
            max-height: 85vh;
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;
        }
    `;

    const modalCssList = [modalCss];

    let initialClientY = 0;
    let currentClientY = 0;

    let isDraggable = false;

    function getModalElement() {
        return modalRef.current.dialog.querySelector('.modal-dialog');
    }

    function getModalBodyElement() {
        return modalRef.current.dialog.querySelector('.modal-body');
    }

    function getClientY(e) {
        return e.targetTouches[0].clientY;
    }

    function onTouchStart(e) {
        let targetEl = e.target;

        const modalOutermostEl = modalRef.current.dialog;
        while (targetEl) {
            if (targetEl.classList.contains(isHeaderClass)) {
                break;
            }

            const targetParentEl = targetEl.parentElement;

            if (targetParentEl === modalOutermostEl) {
                targetEl = null;
            } else {
                targetEl = targetParentEl;
            }
        }

        // Modal can be dragged if
        // 1) Touch action starts on header
        // 2) Touch action starts on body or footer and content is not scrolled yet
        if (targetEl !== null) {
            isDraggable = true;
        } else {
            const modalBodyEl = getModalBodyElement();
            if (modalBodyEl.scrollTop === 0) {
                isDraggable = true;
            }
        }

        initialClientY = getClientY(e);
    }

    function onTouchMove(e) {
        if (!isDraggable) return;

        const modalEl = getModalElement();
        currentClientY = getClientY(e);

        if (currentClientY > initialClientY) {
            // Scrolling down
            modalEl.style.bottom = `-${currentClientY - initialClientY}px`;
        } else {
            // Scrolling up
            modalEl.style.bottom = 0;
        }
    }

    function onTouchEnd() {
        if (isDraggable) {
            const modalEl = getModalElement();
            const thresholdToClose = 125;

            // Close dialog
            if (currentClientY - initialClientY >= thresholdToClose) {
                modalEl.classList.add(closeDialogClass);
            } else {
                const computedStyle = window.getComputedStyle(modalEl);
                const bottomValue = computedStyle.getPropertyValue('bottom');
                const bottomValueInInt = parseInt(bottomValue, 10);

                // Restore dialog position
                if (bottomValueInInt < 0) {
                    modalEl.classList.add(restorePositionClass);
                    modalEl.style.bottom = 0;
                    setTimeout(() => modalEl.classList.remove(restorePositionClass), 150);
                }
            }
        }

        // Reset settings
        initialClientY = 0;
        currentClientY = 0;
        isDraggable = false;
    }

    function onAnimationEnd(e) {
        if (e.animationName === 'slide-down') {
            const modalEl = getModalElement();
            const backButtonEl = modalEl.querySelector(`#${DocumentIdConstants.ComponentBackButton}`);
            backButtonEl.click();
        }
    }

    function onDialogOpened() {
        ZIndexUtils.updateZIndexWithClassName('.modal-backdrop');
        ZIndexUtils.updateZIndexWithComponent(modalRef.current.dialog);

        if (onOpened) {
            onOpened();
        }
    }

    return (
        <Modal
            dialogClassName="apps-component-bottom-sheet-dialog modal-dialog-scrollable"
            css={modalCssList}
            show={isOpen}
            onHide={closeDialog}
            onShow={onDialogOpened}
            enforceFocus={false}
            onTouchStart={onTouchStart}
            onTouchMove={onTouchMove}
            onTouchEnd={onTouchEnd}
            onAnimationEnd={onAnimationEnd}
            ref={modalRef}
            {...rest}
        >
            {useDefaultHeader &&
                <div className={isHeaderClass}>
                    <div className="d-flex justify-content-center mt-2">
                        <SwipeableHintIcon useDarkBackground />
                    </div>

                    <BackButton className="d-none" onClick={closeDialog} />

                    {title &&
                        <Modal.Header className="align-items-center justify-content-center">
                            <Modal.Title className="fw-bold" as="h6">
                                {title}
                            </Modal.Title>

                            {titleIconText && (
                                <Overlay
                                    trigger="click"
                                    placement="bottom"
                                    popoverText={titleIconText}
                                >
                                    <div
                                        className="col-1 icon-icon-info text-muted ms-2"
                                        onClick={EventUtils.cancelEventPropagation}
                                    />
                                </Overlay>
                            )}
                        </Modal.Header>
                    }
                </div>
            }

            <Modal.Body className="p-0">
                {children}
            </Modal.Body>

            {footer && footer}
        </Modal>
    );
}

const SwipeableHintIcon = ({ useDarkBackground }) =>
    <span
        style={{
            width: '36px',
            height: '5px',
            background: useDarkBackground ? '#767676' : '#fff',
            borderRadius: '20px'
        }}
    />;

export { BottomSheetDialog as default, SwipeableHintIcon };