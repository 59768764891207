import withSettings from 'hocs/withSettings';
import * as ComponentUtils from 'utils/component';
import * as NumberUtils from 'utils/numberUtils';

const MenuItemPrice = ({
    as: Element,
    className,
    style,
    value,
    prefix,
    suffix,
    prefixSmallFont,
    suffixSmallFont,
    isCurrencyFormat,
    settings }) => {

    const { localCurrencySymbol, currencyFormat, priceFormat } = settings;

    const format = isCurrencyFormat ? currencyFormat : priceFormat;
    const priceText = NumberUtils.formatNumber(value, format);

    const getPrefixText = () => {
        return prefix ? `${prefix} ${localCurrencySymbol}` : localCurrencySymbol;
    };

    const renderText = (text, isSmallFont) => (
        <span style={{ fontSize: isSmallFont ? '0.625rem' : undefined }}>
            {text}
        </span>
    );

    return (
        <Element className={className} style={style}>
            {renderText(getPrefixText(), prefixSmallFont)}&nbsp;{priceText}
            {suffix && (
                <>
                    &nbsp;{renderText(suffix, suffixSmallFont)}
                </>
            )}
        </Element>
    );
}

const hocs = [
    withSettings
];

export default ComponentUtils.compose(hocs)(MenuItemPrice);