import Guid from 'devextreme/core/guid';

export function generate() {
    return new Guid().toString();
}

export function isValid(value) {
    if (typeof value !== 'string') return false;
    
    const guidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    return guidRegex.test(value);
}