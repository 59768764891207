import Button from 'components/Button';
import Icon from 'components/Icon';
import withOrderService from 'hocs/withOrderService';
import withSettings from 'hocs/withSettings';
import * as ComponentUtils from 'utils/component';
import * as NumberUtils from 'utils/numberUtils';
import FinalTotalIcon from '../Shared/FinalTotalIcon';

const Footer = ({ cart, orderSessionData, settings, confirmOrder }) => {
    const { finalTotal } = cart;
    const { useEPayment } = orderSessionData;
    const { localCurrencySymbol, currencyFormat, allowPayLaterAtCounter, restaurantUseEPayment } = settings;

    return (
        <div
            className="d-flex flex-column position-sticky bottom-0 px-3"
            style={{ borderTop: '1px solid var(--grey-300)', boxShadow: 'var(--box-shadow)' }}
        >
            <div className="d-flex align-items-center fs-3 fw-bold my-3">
                <span className="me-2">Final Total</span>

                {(!useEPayment || allowPayLaterAtCounter) &&
                    <FinalTotalIcon />
                }

                <span className="ms-auto">
                    {`${localCurrencySymbol} ${NumberUtils.formatNumber(finalTotal, currencyFormat)}`}
                </span>
            </div>

            <Button
                className="apps-general-shadow-pulse mb-3"
                primary
                onClick={confirmOrder}
            >
                <div className="d-flex align-items-center justify-content-center">
                    {useEPayment && restaurantUseEPayment
                        ? <Icon
                            name="fas fa-wallet"
                            label={allowPayLaterAtCounter ? "Select Payment Method" : "Select Payment Channel"}
                            showIconFirst={false}
                        />
                        : 'Confirm Order'
                    }
                </div>
            </Button>
        </div>
    );
}

const hocs = [
    withOrderService,
    withSettings
];

const FooterWithHocs = ComponentUtils.compose(hocs)(Footer);

export { FooterWithHocs as InCartTabFooter };