import Button from 'components/Button';
import HintText from 'components/HintText';
import Image from 'components/Image';

const EmptyItem = ({ title, description, imageSrc, closeDialog }) => (
    <div
        className="position-absolute d-flex flex-column align-items-center justify-content-center w-100"
        style={{
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
        }}
    >
        <Image
            style={{ objectFit: 'contain' }}
            imageSrc={imageSrc}
            width="200px"
            height="200px"
        />

        <span className="fw-bold mt-3">{title}</span>

        <HintText className="mt-3">{description}</HintText>

        <Button
            primary
            className="mt-4 px-4"
            onClick={closeDialog}
        >
            Go to Menu
        </Button>
    </div>
);

export default EmptyItem;